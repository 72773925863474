/* p-home-faq
----------------------------------------- */
@use '../../global' as *;

.p-home-faq {
  padding: remhalf(150) 0 0;
  position: relative;
  @include responsive(md) {
    max-width: 1540px;
    margin: 0 auto;
    background-color: #231112;
    padding: rem(150) 0 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      left: rem(10);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      right: rem(10);
    }
  }
}

.p-home-faq__bottom {
  @include responsive(md) {
    background-image: url(../images/home/brown_bg_bottom_pc.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    width: 100%;
    height: rem(130);
    position: absolute;
    bottom: rem(-130);
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.p-home-faq__wrap {
  padding: remhalf(100) 0;
  background-color: #202020;
  margin: 0 width(40, 780);
  @include responsive(md) {
    padding: rem(80) 0 rem(100);
    margin: 0 rem(244);
  }
}

.p-home-faq__listInner {
  margin: remhalf(102) 0 0;
  padding: 0 remhalf(40);
  @include responsive(md) {
    margin: rem(106) 0 0;
    padding: 0 rem(126);
  }
}

.p-home-faq__list {
}

.p-home-faq__item {
  border-bottom: 1px solid #0f0304;
  &:first-child {
    border-top: 1px solid #0f0304;
  }
}

.p-home-faq__itemTitle {
  padding: remhalf(15) remhalf(20) remhalf(15) remhalf(67);
  position: relative;
  cursor: pointer;
  @include responsive(md) {
    padding: rem(24) 0 rem(24) rem(60);
  }
}

.--close {
}

.p-home-faq__itemTitle--head {
  position: absolute;
  left: remhalf(13);
  top: remhalf(30);
  color: #5276d3;
  font-family: $font-family-english;
  @include fonthalf(40, 19, 400, 0, rem);
  @include responsive(md) {
    @include mfont(18, 34, 19, 400, 0, rem);
    left: rem(10);
    top: rem(32);
  }
}

.--question {
}

.p-home-faq__itemTitle--text {
  @include fonthalf(24, 48, 400, 200, rem);
  color: $color-white;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}

.p-home-faq__itemContent {
  display: none;
}

.p-home-faq__itemContentText {
  padding: remhalf(13) remhalf(20) remhalf(33) remhalf(67);
  position: relative;
  @include responsive(md) {
    padding: rem(10) 0 rem(24) rem(60);
  }
}

.p-home-faq__itemContentText--head {
  position: absolute;
  left: remhalf(13);
  top: remhalf(28);
  color: $color-gold;
  font-family: $font-family-english;
  @include fonthalf(40, 19, 400, 0, rem);
  @include responsive(md) {
    @include mfont(18, 34, 19, 400, 0, rem);
    left: rem(10);
    top: rem(18);
  }
}

.p-home-faq__itemContentText--text {
  @include fonthalf(24, 48, 400, 200, rem);
  color: $color-white;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}
