/* animation
----------------------------------------- */
@use '../global' as *;

.inview {
  // visibility: hidden;
  opacity: 0;
  transition: opacity 1.1s $transition-base, transform 1.1s $transition-base;
  transform: translateY(10%);
  &.is-anim {
    // visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
