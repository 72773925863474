/* p-faq
----------------------------------------- */
@use '../../global' as *;

.p-faq-list {
  h3 {
    @include fonthalf(40, 48, 400, 200, rem);
    @include responsive(md) {
      @include font(36, 48, 400, 200, rem);
    }

    padding-top: rem(112);
    text-align: center;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: $color-gold;
    }

    &::before {
      margin-bottom: rem(26);
    }

    &::after {
      margin-top: rem(26);
    }
  }

  &:first-of-type {
    h3 {
      padding-top: 0;
    }
  }
}

.p-faq-list__columns {
  display: flex;
  flex-direction: column;
  gap: rem(80);
  padding: rem(112) 0;
}

.p-faq-card {
  border-top: 1px solid rgba($color-tanzo-beige, 0.2);
  padding-top: rem(80);

  *:not(.p-faq-card__label) {
    font-family: $font-family-base02;
  }

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: 1px solid rgba($color-tanzo-beige, 0.2);
    padding-bottom: rem(80);
  }

  h4 {
    @include fonthalf(32, 48, 400, 200, rem);
    @include responsive(md) {
      @include mfont(16, 16, 32, 400, 200, rem);
    }
  }

  .p-faq-card__label {
    position: relative;
    padding-right: rem(12);
    left: 0;
    top: remhalf(3);
    @include fonthalf(40, 19, 400, 0, rem);
    @include responsive(md) {
      @include mfont(18, 34, 19, 400, 0, rem);
      top: rem(2);
    }

    &.label-q {
      color: #5276d3;
    }

    &.label-a {
      color: $color-gold;
    }
  }

  .p-faq-card__container {
    display: flex;
    gap: rem(24);
    margin-top: rem(32);
  }

  .p-faq-card__answer {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    @include fonthalf(24, 48, 400, 200, rem);
    @include responsive(md) {
      @include mfont(12, 16, 32, 400, 200, rem);
    }
  }

  .p-faq-card__linkContainer {
    margin-top: rem(32);
  }

  .p-faq-card__linkRows {
    display: flex;
    flex-direction: column;
    gap: rem(32);
  }
}
