/* section
----------------------------------------- */
@use '../global' as *;

.l-content {
  position: relative;
}

.l-section-title {
  &.--white {
    .l-section-title__eng {
      @include fonthalf(20, 23, 400, 200, rem);
      background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 0%, #f9f8f5 0%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0 0 remhalf(20);
      @include responsive(md) {
        @include mfont(12, 20, 22, 400, 200, rem);
        padding: 0 0 rem(21);
      }
      &:before {
        content: '';
        width: remhalf(62);
        height: 1px;
        background-color: $color-white;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
        @include responsive(md) {
          width: rem(62);
        }
      }
    }
    .l-section-title__jp {
      margin: remhalf(10) 0 0;
      font-family: $font-family-base;
      @include fonthalf(40, 65, 400, 200, rem);
      @include responsive(md) {
        @include mfont(20, 40, 65, 400, 200, rem);
        margin: rem(10) 0 0;
      }
    }
  }
}

_::-webkit-full-page-media,
_:future,
:root .l-section-title.--white .l-section-title__eng {
  @include responsive(md) {
    margin: rem(-1) 0 0;
  }
}

.l-section-title--ornament {
  position: relative;
  &.--white {
    &::before {
      background-image: url(../images/common/title_ornament_white.svg);
      top: remhalf(-40);
      @include responsive(md) {
        width: rem(84);
        height: rem(26);
        top: rem(-38);
      }
    }
  }
  &:before {
    content: '';
    background-image: url(../images/common/title_ornament.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: remhalf(84);
    height: remhalf(26);
    position: absolute;
    top: remhalf(-26);
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(84);
      height: rem(26);
      top: rem(-28);
    }
  }
}

.l-section-title__eng {
  font-family: $font-family-english;
  @include fonthalf(60, 65, 400, 100, rem);
  background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 52.08%, #f9f8f5 52.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  text-align: center;
  padding: 0 0 remhalf(13);
  position: relative;
  @include responsive(md) {
    @include mfont(24, 46, 50, 400, 100, rem);
    padding: 0 0 rem(14);
  }
  &:before {
    content: '';
    width: remhalf(60);
    height: 1px;
    background-color: $color-gold;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(60);
    }
  }
}

.l-section-title__jp {
  display: block;
  margin: remhalf(14) 0 0;
  text-align: center;
  color: $color-white;
  @include fonthalf(20, 20, 400, 200, rem);
  font-family: $font-family-base02;
  @include responsive(md) {
    margin: rem(14) 0 0;
    @include mfont(10, 14, 14, 400, 200, rem);
  }
}

.l-section-brown {
  position: relative;
  background-color: #231112;
  padding: remhalf(100) 0 remhalf(150);
  @include responsive(md) {
    padding: rem(126) width(190, 1920) rem(130);
    background-color: #000000;
  }
  &:before {
    content: '';
    background-image: url(../images/home/brown_bg_top_sp.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: remhalf(200);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      display: none;
    }
  }
  &:after {
    content: '';
    background-image: url(../images/home/brown_bg_bottom_sp.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    width: 100%;
    height: remhalf(150);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      display: none;
    }
  }
}
