@use '../global' as *;

// ===========================
// space (padding and margin)
// ===========================

// ---------------------------
// margin
// ---------------------------

// all
@for $i from 1 through 10 {
  .u-m-#{$i} {
    margin: $i * 1rem;
  }
}

// top
@for $i from 1 through 10 {
  .u-mt-#{$i} {
    margin-top: $i * 1rem;
  }
}

// right
@for $i from 1 through 10 {
  .u-mr-#{$i} {
    margin-right: $i * 1rem;
  }
}

// bottom
@for $i from 1 through 10 {
  .u-mb-#{$i} {
    margin-bottom: $i * 1rem;
  }
}

// left
@for $i from 1 through 10 {
  .u-ml-#{$i} {
    margin-left: $i * 1rem;
  }
}

// top and bottom
@for $i from 1 through 10 {
  .u-my-#{$i} {
    margin-top: $i * 1rem;
    margin-bottom: $i * 1rem;
  }
}

// right and left
@for $i from 1 through 10 {
  .u-mx-#{$i} {
    margin-right: $i * 1rem;
    margin-left: $i * 1rem;
  }
}

.u-mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.u-m-overflow {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

// ---------------------------
// padding
// ---------------------------

// all
@for $i from 1 through 10 {
  .u-p-#{$i} {
    padding: $i * 1rem;
  }
}

// top
@for $i from 1 through 10 {
  .u-pt-#{$i} {
    padding-top: $i * 1rem;
  }
}

// right
@for $i from 1 through 10 {
  .u-pr-#{$i} {
    padding-right: $i * 1rem;
  }
}

// bottom
@for $i from 1 through 10 {
  .u-pb-#{$i} {
    padding-bottom: $i * 1rem;
  }
}

// left
@for $i from 1 through 10 {
  .u-pl-#{$i} {
    padding-left: $i * 1rem;
  }
}

// top and bottom
@for $i from 1 through 10 {
  .u-py-#{$i} {
    padding-top: $i * 1rem;
    padding-bottom: $i * 1rem;
  }
}

// right and left
@for $i from 1 through 10 {
  .u-px-#{$i} {
    padding-right: $i * 1rem;
    padding-left: $i * 1rem;
  }
}
