/* p-shop-detail
----------------------------------------- */
@use '../../global' as *;

body {
  &.shop-detail {
    .p-shop {
      padding: remhalf(190) 0 0;
      @include responsive(md) {
        padding: rem(100) 0 0;
      }
      .p-home-voice {
        background-color: #231112;
        padding: remhalf(94) 0 remhalf(70);
        @include responsive(md) {
          padding: rem(106) 0 rem(60);
          background-color: rgba(255, 255, 255, 0);
        }
        &:before,
        &:after {
          @include responsive(md) {
            display: none;
          }
        }
        &:before {
          @include responsive(md) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100vw;
            height: 100%;
            background-color: #231112;
            display: block;
          }
        }
        .p-home-voice__inner {
          margin: remhalf(76) 0 0;
          @include responsive(md) {
            margin: rem(92) auto 0;
            width: rem(1049);
            max-width: 1049px;
            min-width: 620px;
          }
          #js-shopvoice-slider {
            @include responsive(md) {
              width: rem(1049);
              min-width: 620px;
              margin: 0 auto;
            }
          }
        }
      }
    }
    .p-banner {
      padding: remhalf(100) 0 0;
      background-color: #0f0304;
      @include responsive(md) {
        padding: rem(100) 0;
      }
    }
  }
}

.p-shop-detail {
  padding: remhalf(33) 0 remhalf(149);
  @include responsive(md) {
    padding: rem(26) 0 rem(185);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      min-width: 704px;
      max-width: 1060px;
      width: rem(1060);
      margin: 0 auto;
    }
  }
}

.p-ring-detail__head {
}

.p-ring-detail__slider {
  padding: 0 0 remhalf(49);
  margin: 0 0 remhalf(58);
  position: relative;
  @include responsive(md) {
    padding: 0 0 33px;
    margin: 0 0 52px;
  }
  .splide__pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    li {
      button {
        border: 1px solid #8f7f58;
        background-color: rgba(255, 255, 255, 0);
        width: rem(9);
        height: rem(9);
        opacity: 1;
        margin: 0 remhalf(14);
        @include responsive(md) {
          margin: 0 7px;
          width: 9px;
          height: 9px;
        }
        &.is-active {
          transform: scale(1);
          background-color: #8f7f58;
        }
      }
    }
  }
}

.p-ring-detail__sliderList {
}

.p-ring-detail__sliderItem {
}

.p-ring-detail__sliderFigure {
  @include img(900, 575);
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-ring-detail__headTitle {
  padding: rem(9) 0 rem(11);
  border-top: 1px solid $color-gold;
  border-bottom: 1px solid $color-gold;
  @include fonthalf(30, 50, 400, 200, rem);
  text-align: center;
  color: $color-white;
  margin: 0 0 remhalf(40);
  @include responsive(md) {
    padding: rem(18) 0 rem(22);
    @include mfont(16, 26, 50, 400, 200, rem);
    margin: 0 0 rem(55);
  }
}

.p-shop-detail__content {
}

.p-shop-detail__contentDescription {
  color: $color-white;
  @include fonthalf(22, 40, 400, 100, rem);
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 100, rem);
  }
}

.p-shop-detail__contentList {
  margin: remhalf(52) 0 0;
  border-top: 1px solid rgba(191, 183, 163, 0.2);
  @include responsive(md) {
    margin: rem(45) 0 0;
  }
}

.p-shop-detail__contentRow {
  padding: remhalf(16) 0;
  border-bottom: 1px solid rgba(191, 183, 163, 0.2);
  @include flex(flex-start, flex-start, row);
  @include responsive(md) {
    padding: rem(8) 0;
  }
  &.--tel {
    .p-shop-detail__contentItem {
      color: $color-gold;
      @include fonthalf(26, 32, 700, 200, rem);
      @include responsive(md) {
        @include mfont(12, 20, 32, 700, 200, rem);
      }
    }
    .p-shop-detail__contentDetail {
      a {
        display: block;
        color: $color-gold;
        font-family: $font-family-base02;
        @include fonthalf(26, 32, 700, 200, rem);
        @include responsive(md) {
          @include mfont(12, 20, 32, 700, 200, rem);
        }
      }
    }
  }
}

.p-shop-detail__contentItem {
  width: remhalf(96);
  margin: 0 remhalf(27) 0 0;
  flex-shrink: 0;
  font-family: $font-family-base02;
  @include fonthalf(20, 32, 400, 200, rem);
  color: $color-white;
  @include responsive(md) {
    min-width: 70px;
    width: rem(70);
    margin: 0 rem(13) 0 0;
    @include mfont(12, 14, 32, 400, 200, rem);
  }
}

.p-shop-detail__contentDetail {
  width: calc(100% - 3.6rem);
  @include fonthalf(20, 32, 400, 200, rem);
  font-family: $font-family-base02;
  color: $color-white;
  @include responsive(md) {
    width: calc(100% - 5.1875rem);
    @include mfont(12, 14, 32, 400, 200, rem);
  }
}

.p-shop-detail__contentMap {
  margin: remhalf(50) 0 0;
  @include responsive(md) {
    margin: rem(60) 0 0;
  }
  .c-button {
    @include responsive(md) {
      width: rem(291);
      min-width: 290px;
    }
  }
}

.p-shop-detail__contentMapFigure {
  @include img(900, 575);
  margin: 0 0 remhalf(50);
  @include responsive(md) {
    margin: 0 0 rem(60);
  }
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}
