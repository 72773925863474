/* spacer
----------------------------------------- */
@use '../global' as *;

// ===========================
// 移動コーディングなどでFV見出し画像がない場合のスペーサー
// ===========================
.c-non-head-spacer {
  margin-top: rem(65);

  @include responsive(md) {
    margin-top: rem(100);
  }
}
