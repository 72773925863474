/* p-home-about
----------------------------------------- */
@use '../../global' as *;

.p-home-about {
  height: 100vh;
  position: relative;
}

.p-home-about__wrap {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  &.is-anim {
    transform: translate(-50%, -50%);
  }
}

.p-home-about__title {
}

.p-home-about__titleEng {
  white-space: nowrap;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-align: center;
  color: $color-white;
  @include fonthalf(60, 65, 400, 100, rem);
  padding: 0 0 remhalf(23);
  position: relative;
  display: block;
  @include responsive(md) {
    @include mfont(36, 46, 50, 400, 100, rem);
    padding: 0 0 rem(23);
  }
  &:before {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    content: '';
    width: remhalf(60);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(60);
    }
  }
}

.p-home-about__titleJp {
  @include fonthalf(20, 20, 400, 200, rem);
  text-align: center;
  color: $color-white;
  display: block;
  margin: remhalf(14) 0 0;
  font-family: $font-family-base02;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @include responsive(md) {
    margin: rem(14) 0 0;
    @include mfont(14, 14, 14, 400, 200, rem);
  }
}

.p-home-about__text {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-align: center;
  margin: remhalf(48) 0 0;
  @include fonthalf(24, 48, 400, 200, rem);
  color: $color-white;
  width: 100%;
  @include responsive(md) {
    margin: rem(44) 0 0;
    @include mfont(14, 20, 45, 400, 200, rem);
  }
}

.u-only-sp {
}
