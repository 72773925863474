/* _p-possession（リフォーム）
----------------------------------------- */
@use '../../global' as *;

.p-possession-container {
  @include fonthalf(24, 48, 400, 200, rem);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }

  :not(h2) {
    font-family: $font-family-base02;
    word-break: auto-phrase;
  }
}

.p-possession-section-container {
  display: flex;
  flex-direction: column;
  gap: rem(160);
  margin-top: rem(64);

  @include responsive(md) {
    gap: rem(240);
  }
}

.p-possession-section {
  h2 {
    border-bottom: none;
  }
}

.p-possession-section__body {
  margin-top: rem(32);

  @include responsive(md) {
    margin-top: rem(64);
  }

  p {
    text-align: justify;
  }
}

.p-possession-section__aside-image {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  @include responsive(md) {
    flex-direction: row;
  }

  .aside-image__fill {
    flex-grow: 2;
  }

  .aside-image__fixed {
    flex-shrink: 0;
  }
}

.p-possession-section__row-images {
  display: flex;
  justify-content: center;
  gap: rem(32);
  padding: rem(32) 0;

  @include responsive(md) {
    align-items: center;
  }
}

.p-possession-section__link-container {
  margin-top: rem(80);
  text-align: center;
}

.p-possession-section__center-img {
  width: 380px;
  height: 285px;
  margin: auto;
}
