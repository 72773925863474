/* p-nyuseki
----------------------------------------- */
@use '../../global' as *;

.p-nyuseki-about {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-nyuseki-container {
  .p-after-row-links {
    align-items: start;
  }
}
