/* p-home-column
----------------------------------------- */
@use '../../global' as *;

.p-home-column {
  margin: remhalf(38) 0 0;
  padding: remhalf(112) width(40, 780) 0;
  position: relative;
  @include md {
    padding: rem(150) rem(140) 0;
    background-color: #231112;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  @include responsive(lg) {
    padding: rem(150) rem(244) 0;
    background-color: #231112;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      left: rem(10);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      right: rem(10);
    }
  }
}

.p-home-column__inner {
  @include responsive(md) {
    @include flex(space-between, flex-start, row);
  }
  .l-section-title {
    @include md {
      flex-shrink: 0;
      margin: 0 rem(56) 0 0;
    }
    @include responsive(lg) {
      width: width(176, 1052);
    }
  }
}

.p-home-column__content {
  margin: remhalf(102) 0 0;
  @include responsive(md) {
    margin: 0;
    width: width(814, 1052);
  }
}

.p-home-column__list {
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    margin: 0 0 rem(40);
  }
}

.p-home-column__item {
  &:not(:last-child) {
    margin: 0 0 remhalf(33);
    @include responsive(md) {
      margin: 0 0 rem(30);
    }
  }
}

.p-home-column__link {
  @include flex(space-between, flex-start, row);
  transition: opacity 0.5s $transition-base;
  @include hover {
    opacity: 0.7;
  }
}

.p-home-column__figure {
  width: width(211, 700);
  @include img(211, 165);
  @include responsive(md) {
    @include img(173, 122);
    width: width(173, 814);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-home-column__itemContent {
  width: width(462, 700);
  @include responsive(md) {
    width: width(612, 814);
  }
}

.p-home-column__itemCat {
  color: $color-white;
  @include fonthalf(20, 30, 400, 200, rem);
  font-family: $font-family-base02;
  padding: 0 0 remhalf(12);
  margin: 0 0 remhalf(15);
  display: block;
  position: relative;
  @include responsive(md) {
    @include mfont(12, 14, 14, 400, 200, rem);
    padding: 0 0 rem(17);
    margin: 0 0 rem(12);
  }
  &:before {
    content: '';
    width: remhalf(60);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    left: 0;
    bottom: 0;
    @include responsive(md) {
      width: rem(60);
    }
  }
}

.p-home-column__itemTitle {
  @include fonthalf(24, 48, 400, 200, rem);
  font-family: $font-family-base02;
  color: $color-white;
  @include line-clamp(2);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}
