/* header
----------------------------------------- */
@use '../global' as *;

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: rem(65);
  padding: 0 rem(20);
  background-color: #0f0304;

  @include flex(space-between, center, row);
  @include responsive(md) {
    height: rem(100);
  }
}

.l-header__inner {
  width: 100%;

  @include flex(space-between, center, row);
}

.l-header__logo {
  z-index: 3;
  width: rem(120);
  height: remhalf(59);
  position: absolute;
  top: calc(50% + 0.1rem);
  left: 50%;
  transform: translate(-50%, -50%);
  @include responsive(md) {
    transform: translate(0%, 0%);
    top: rem(30);
    left: rem(70);
    width: rem(200);
    height: rem(49);
  }
}

.l-header__logoLink {
  display: block;
}

.l-header-nav {
  background-color: #202020;
  width: 100%;
  height: rem(30);
  position: absolute;
  bottom: remhalf(-60);
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0 0 0 rem(20);
  @include responsive(md) {
    background-color: #0f0304;
    height: 100%;
    transform: translate(0%, 0%);
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    padding: 0;
  }
}

.l-header-nav__list {
  height: 100%;
  @include flex(space-between, center, row);
  @include responsive(md) {
    @include flex(center, center, row);
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.l-header-nav__item {
  height: 100%;
  @include flex(center, center, row);
  @include responsive(md) {
    position: relative;
    cursor: pointer;
    pointer-events: all;
  }
  &.--online {
    @include responsive(md) {
      margin: 0 !important;
    }
  }
  &.--pc {
    display: none;
    @media screen and (min-width: 1100px) {
      @include flex(center, center, row);
    }
    // @include responsive(md) {
    //   @include flex(center, center, row);
    // }
  }
  &:not(:last-child) {
    @include responsive(md) {
      margin: 0 rem(54) 0 0;
    }
  }
}

.l-header-nav__link {
  display: block;
  color: $color-white;
  @include fonthalf(22, 22, 400, 100, rem);
  transition: color 0.5s $transition-base;
  @include responsive(md) {
    color: #fff;
    @include mfont(12, 16, 23, 400, 100, rem);
  }
  @include hover {
    color: #8f7f58;
  }
}

.l-header-nav__item--reserve {
  background-color: #8f7f58;
  @include responsive(md) {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
  }
  .l-header-nav__link {
    font-family: $font-family-base02;
    padding: 0 rem(23);
  }
}

.l-header-nav__item--reserve--pc {
  display: none;
  @include responsive(md) {
    display: block;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    @include flex(center, center, row);
  }
  .l-header-nav__link {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #8f7f58;
    padding: 0 rem(56);
    @include mfont(12, 22, 33, 500, 200, rem);
    font-family: $font-family-base02;
    transition: background-color 0.5s $transition-base, color 0.5s $transition-base;
    @include hover {
      background-color: $color-white;
      color: #8f7f58;
    }
  }
}

// submenu
.l-header-nav__pulldown {
  position: relative;
}
.l-header-submenu {
  display: none;
  position: absolute;
  top: rem(21);
  left: calc(50% + 8rem);
  margin: rem(60) 0 0;
  box-shadow: 0 rem(46) rem(99) rgba(0, 0, 0, 0.09);
  cursor: default;
  transform: translate(-50%, 2rem);
  background-color: rgba(15, 3, 4, 0.8);
  @include responsive(md) {
    display: block;
  }
  &::before {
    position: absolute;
    top: rem(-21);
    left: 50%;
    width: 100%;
    height: rem(21);
    background-color: rgba(0, 0, 0, 0);
    content: '';
    cursor: pointer;
    transform: translate(-50%, 0%);
    pointer-events: all;
  }
  &:after {
    content: '';
    width: calc(100% - 0.625rem);
    height: calc(100% - 0.625rem);
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #bfb7a3;
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }

  &.is-none {
    visibility: hidden;
    opacity: 0;
    transition: transform 0.5s $transition-base, opacity 0.5s $transition-base, visibility 0.5s $transition-base;
    transform: translate(-50%, 2rem);
    pointer-events: none;
  }
  &.is-active {
    visibility: visible;
    opacity: 1;
    transition: transform 0.5s $transition-base, opacity 0.5s $transition-base, visibility 0.5s $transition-base;
    transform: translate(-50%, 0%);
    pointer-events: all;
  }
}

.l-header-submenuInner {
  padding: rem(20) rem(50);
  @include flex(flex-start, flex-start, row);
}

.l-header-submenuHead {
  margin: 0 rem(56) 0 0;
}

.l-header-submenuHeadEng {
  color: $color-white;
  @include mfont(12, 20, 36, 400, 100, rem);
  display: block;
  margin: 0 0 rem(32);
  white-space: nowrap;
}

.l-header-submenuHeadTitle {
}

.l-header-submenuHeadTitleLink {
  display: block;
}

.l-header-submenuHeadTitleText {
  color: $color-white;
  min-width: 136px;
  @include mfont(12, 14, 32, 400, 200, rem);
  border-bottom: 1px solid $color-white;
  display: block;
  position: relative;
  white-space: nowrap;
  &:before {
    content: '';
    width: rem(12);
    height: rem(12);
    background-image: url(../images/common/megamenu_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
  }
}

.l-header-submenuHeadTitleTop {
  color: $color-white;
  @include mfont(12, 14, 32, 400, 200, rem);
  display: block;
}

.l-header-submenu__list {
}

.l-header-submenu__item {
}

.l-header-submenu__link {
  white-space: nowrap;
  color: $color-white;
  @include mfont(12, 14, 33, 400, 100, rem);
  display: block;
  padding: 0 0 0 rem(18);
  position: relative;
  &:before {
    content: '';
    width: rem(12);
    height: rem(12);
    background-image: url(../images/common/megamenu_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
  }
}
