/* p-home-feature
----------------------------------------- */
@use '../../global' as *;

.p-home-feature {
  background-color: #0f0304;
  padding: remhalf(110) 0 remhalf(218);
  position: relative;
  @include responsive(md) {
    padding: rem(92) 0 rem(151);
  }
  .l-container {
    padding: 0 width(30, 780);
    @include responsive(md) {
      // max-width: 1228px;
      // padding: 0 rem(80);
      max-width: 1068px;
      padding: 0;
    }
  }
}

.p-home-feature__text {
  background-image: url(../images/home/feature_text.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  position: absolute;
  bottom: rem(56);
  right: rem(6);
  width: remhalf(284);
  height: remhalf(134);
  @include responsive(md) {
    width: rem(284);
    height: rem(134);
    right: widthvw(310, 1920);
    bottom: widthvw(42, 1920);
  }
}

.p-home-feature__wrap {
  padding: remhalf(220) remhalf(112) remhalf(137);
  background-image: url(../images/home/feature_bg_img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @include responsive(md) {
    padding: rem(177) rem(251) rem(100);
    background-image: url(../images/home/feature_bg_img_pc.png);
  }
}

.p-home-feature__list {
  max-width: 242px;
  margin: 0 auto;
  @include responsive(md) {
    // max-width: 567px;
    max-width: none;
    width: max(35.4375rem, 300px);
  }
}

.p-home-feature__item {
  border-bottom: 1px solid rgba(191, 183, 163, 0.2);
  &:first-child {
    border-top: 1px solid rgba(191, 183, 163, 0.2);
  }
}

.p-home-featureLink {
  padding: remhalf(21) 0;
  @include flex(center, center, row);
  transition: opacity 0.5s $transition-base;
  @include responsive(md) {
    padding: rem(19.5) 0;
  }
  @include hover {
    opacity: 0.5;
  }
}

.p-home-feature__itemNo {
  font-family: $font-family-english;
  @include fonthalf(50, 50, 400, 100, rem);
  font-style: italic;
  background: linear-gradient(180deg, #f9f8f5 0%, #979797 56.77%, #f9f8f5 56.78%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 remhalf(9) 0 0;
  display: block;
  @include responsive(md) {
    @include mfont(22, 40, 30, 400, 100, rem);
    margin: 0 rem(10) 0 0;
  }
}

.p-home-feature__itemText {
  display: block;
  color: $color-white;
  @include fonthalf(24, 24, 400, 200, rem);
  @include responsive(md) {
    @include mfont(14, 20, 24, 400, 200, rem);
  }
}
