/* l-form
----------------------------------------- */
@use '../global' as *;

.l-form {
  margin: remhalf(50) 0 0;
  @include responsive(md) {
    margin: rem(70) 0 0;
  }
}

.l-form__btnBlock {
  position: relative;
}

.l-form__list {
  padding: 0 remhalf(50);
  @include responsive(md) {
    padding: 0;
  }
}

.l-form__row {
  @include responsive(md) {
    @include flex(flex-start, flex-start, row);
  }
  &:not(:last-child) {
    margin: 0 0 remhalf(50);
    @include responsive(md) {
      margin: 0 0 rem(40);
    }
  }
  &.--shop {
    @include responsive(md) {
      margin: 0 0 rem(52);
    }
  }
  &.--date {
    @include responsive(md) {
      margin: 0 0 rem(70);
    }
  }
  &.--textarea {
    @include responsive(md) {
      margin: rem(74) 0 0;
    }
  }
}

.l-form__item {
  margin: 0 0 remhalf(20);
  @include responsive(md) {
    margin: 0 width(26, 800) 0 0;
    width: width(250, 800);
  }
  p {
    @include flex(flex-start, center, row);
  }
}

.l-form__item--title {
  color: $color-white;
  font-family: $font-family-base02;
  display: block;
  @include fonthalf(22, 32, 400, 200, rem);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}

.l-form__item--required {
  color: #ff8a00;
  font-family: $font-family-base02;
  display: block;
}

.l-form__detail {
  @include responsive(md) {
    width: width(524, 800);
  }
  p {
    span {
      display: block;
      input {
        padding: rem(8.8);
        width: 100%;
        background-color: #d9d9d9;
        @include font(12, 17.45, 400, 200, rem);
        font-family: $font-family-base02;
        @include responsive(md) {
          padding: rem(1.5) rem(12);
          @include mfont(12, 16, 32, 400, 200, rem);
        }
      }
      // カレンダー
      input[type='date'] {
        height: rem(35);
        background-color: #d9d9d9;
        position: relative;
        padding: rem(5) rem(8);
        border-radius: 0;
        width: rem(300);
        @include font(13, 17.45, 400, 200, rem);
        color: $color-text;
        font-family: $font-family-base02;
        @include responsive(md) {
          padding: rem(5) rem(16);
          width: rem(524);
          min-height: 25px;
          @include mfont(12, 16, 32, 400, 200, rem);
        }
      }

      input[type='date']::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background: transparent;
        z-index: 1;
      }

      input[type='date']::after {
        content: '';
        background-image: url(../images/contact/calendar_icon_sp.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: remhalf(70);
        height: remhalf(73);
        position: absolute;
        bottom: rem(-1);
        right: rem(-1);
        transform: translate(0%, 0%);
        @include responsive(md) {
          background-image: url(../images/contact/calendar_icon_pc.svg);
          width: rem(43.5);
          min-width: 31px;
          height: rem(37.5);
          min-height: 27px;
          bottom: rem(-1.5);
        }
      }
      textarea {
        width: 100%;
        height: remhalf(297);
        padding: rem(8.8);
        background-color: #d9d9d9;
        @include font(12, 17.45, 400, 200, rem);
        font-family: $font-family-base02;
        @include responsive(md) {
          padding: rem(1.5) rem(12);
          @include mfont(12, 16, 32, 400, 200, rem);
          height: rem(242);
        }
      }
    }
  }
  &.--address {
    p {
      &:first-child {
        margin: 0 0 remhalf(20);
        @include flex(flex-start, flex-start, row);
        @include responsive(md) {
          margin: 0 0 rem(20);
        }
        span {
          width: width(134, 600);
          @include responsive(md) {
            width: rem(95);
          }
          &:first-child {
            margin: 0 remhalf(20) 0 0;
            @include responsive(md) {
              margin: 0 rem(14) 0 0;
            }
          }
        }
      }
    }
  }
  select {
    display: block;
    appearance: none;
    width: 100%;
    height: remhalf(70);
    padding: 0 0 0 remhalf(23);
    border-radius: remhalf(5);
    border: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(162, 162, 162, 1) 100%);
    color: #0f0304;
    word-break: normal;
    cursor: pointer;
    pointer-events: all;
    @include fonthalf(20, 24, 400, 100, rem);
    font-family: $font-family-base02;
    @include responsive(md) {
      height: rem(35);
      min-height: 25px;
      padding: 0 0 0 rem(15);
      @include mfont(12, 12, 24, 400, 100, rem);
      border-radius: rem(5);
    }
  }
  &.--select {
    position: relative;
    &:before {
      content: '';
      background-image: url(../images/contact/select_arrow.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: remhalf(25);
      height: remhalf(14);
      position: absolute;
      top: 50%;
      right: remhalf(28);
      transform: translate(0%, -50%);
      @include responsive(md) {
        width: rem(13);
        height: rem(7);
        right: rem(15);
      }
    }
  }
  &.--checkbox {
    @include responsive(md) {
      margin: rem(4) 0 0;
    }
    p {
      span {
        display: block;
        span {
          @include flex(flex-start, flex-start, row);
          margin: 0 remhalf(55) 0 0;
          @include responsive(md) {
            margin: 0 rem(47) 0 0;
          }
          label {
            @include flex(flex-start, center, row);

            input[type='checkbox'] {
              position: relative;
              width: remhalf(34);
              height: remhalf(34);
              border: none;
              vertical-align: remhalf(-4);
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background-color: #d9d9d9;
              border-radius: 0;
              @include responsive(md) {
                width: rem(16);
                min-width: 12px;
                height: rem(16);
                min-height: 12px;
                vertical-align: rem(-3);
                padding: 0;
              }
            }
            input[type='checkbox']:checked:before {
              position: absolute;
              top: remhalf(4);
              left: remhalf(11);
              transform: rotate(50deg);
              width: rem(6);
              height: rem(10);
              border-right: rem(2) solid #0f0304;
              border-bottom: rem(2) solid #0f0304;
              content: '';
              @include responsive(md) {
                top: rem(2);
                left: 5px;
                width: rem(5);
                min-width: 3px;
                height: rem(10);
                min-height: 8px;
              }
            }
            span {
              margin: 0 0 0 remhalf(20);
              @include fonthalf(22, 24, 400, 100, rem);
              font-family: $font-family-base02;
              color: $color-white;
              display: block;
              @include responsive(md) {
                margin: 0 0 0 rem(8);
                @include mfont(12, 16, 24, 400, 100, rem);
              }
            }
          }
        }
      }
    }
  }
}

.l-form__detailWrap {
  &:not(:last-child) {
    margin: 0 0 rem(10);
    @include responsive(md) {
      margin: 0 0 rem(15);
    }
  }
  span {
    &.--select {
      position: relative;
      &:before {
        content: '';
        background-image: url(../images/contact/select_arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: remhalf(25);
        height: remhalf(14);
        position: absolute;
        top: 50%;
        right: remhalf(28);
        transform: translate(0%, -50%);
        @include responsive(md) {
          width: rem(13);
          height: rem(7);
          right: rem(15);
        }
      }
    }
  }
}

.l-form__detailTitle {
  margin: 0 0 rem(10);
  @include fonthalf(20, 32, 400, 200, rem);
  font-family: $font-family-base02;
  color: $color-white;
  display: block;
  @include responsive(md) {
    @include mfont(12, 12, 24, 400, 200, rem);
    margin: 0 0 rem(7);
  }
}

.l-form__detailText {
  margin: rem(16) 0 0;
  @include fonthalf(20, 24, 400, 100, rem);
  font-family: $font-family-base02;
  color: $color-white;
  @include responsive(md) {
    margin: rem(10) 0 0;
    @include mfont(12, 12, 24, 400, 100, rem);
  }
}

.--address {
}

.--select {
}

.l-form__agree {
  padding: 0 remhalf(50);
  margin: rem(12) 0 rem(24);
  @include responsive(md) {
    width: width(524, 800);
    padding: 0;
    margin: rem(27) 0 rem(53) auto;
  }
  input[type='checkbox'] {
    position: relative;
    width: remhalf(26);
    height: remhalf(26);
    border: none;
    vertical-align: remhalf(-4);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #d9d9d9;
    border-radius: 0;
    @include responsive(md) {
      width: rem(16);
      min-width: 12px;
      height: rem(16);
      min-height: 12px;
      vertical-align: rem(-3);
    }
  }
  input[type='checkbox']:checked:before {
    position: absolute;
    top: remhalf(4);
    left: remhalf(9);
    transform: rotate(50deg);
    width: rem(4);
    height: rem(8);
    border-right: rem(2) solid #0f0304;
    border-bottom: rem(2) solid #0f0304;
    content: '';
    @include responsive(md) {
      top: rem(2);
      left: 5px;
      width: rem(5);
      min-width: 3px;
      height: rem(10);
      min-height: 8px;
    }
  }
}

.l-form__agreeText {
  @include fonthalf(20, 24, 400, 100, rem);
  font-family: $font-family-base02;
  color: $color-white;
  margin: 0 0 0 remhalf(11);
  @include responsive(md) {
    @include mfont(12, 12, 24, 400, 100, rem);
    margin: 0 0 0 rem(8);
  }
}

.l-form__agreeLink {
  @include fonthalf(20, 24, 400, 100, rem);
  font-family: $font-family-base02;
  color: $color-white;
  @include responsive(md) {
    @include mfont(12, 12, 24, 400, 100, rem);
  }
}

.l-form__buttonInner {
  @include responsive(md) {
    width: 100%;
  }
}

.l-form__btnBlock {
  .c-button__arrow {
    @include responsive(md) {
      top: calc(50% + 0.1rem);
    }
  }
}

.l-form__btn {
  cursor: pointer;
  color: $color-white;
  @include fonthalf(24, 26, 400, 200, rem);
  font-family: $font-family-base02;
  @include responsive(md) {
    width: rem(290);
    margin: 0 0 0 auto;
    @include mfont(12, 16, 26, 400, 200, rem);
    min-width: 290px;
  }
}

.l-form-thanks__title {
  @include fonthalf(36, 67.2, 400, 200, rem);
  text-align: center;
  color: $color-white;
  margin: rem(24) 0;
  @include responsive(md) {
    @include mfont(16, 26, 50, 400, 200, rem);
    margin: rem(40) 0;
  }
}

.l-form-thanks__text {
  @include fonthalf(22, 40, 400, 100, rem);
  text-align: center;
  color: $color-white;
  margin: 0 0 rem(34);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 100, rem);
    margin: 0 0 rem(50);
  }
}
