/* p-home-type
----------------------------------------- */
@use '../../global' as *;

.p-home-type {
  padding: remhalf(100) 0;
  background: linear-gradient(180deg, rgba(15, 3, 4, 1) 0%, #370f12 100%);
  @include responsive(md) {
    padding: rem(50) 0 rem(10);
  }
  .l-container {
    padding: 0 width(90, 780);
    @include responsive(md) {
      max-width: 1200px;
      padding: 0 rem(80);
    }
  }
}

.p-home-type__list {
  @include responsive(md) {
    @include flex(space-between, flex-start, row);
    flex-wrap: wrap;
  }
  &.is-trigger {
    .p-home-type__item {
      opacity: 1;
      transform: translateY(0);
      &:nth-of-type(2) {
        transition-delay: 0.2s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.3s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.4s;
      }
    }
  }
}

.p-home-type__item {
  opacity: 0;
  transform: translateY(15%);
  transition: opacity 1.1s ease, transform 1.1s ease;
  @include responsive(md) {
    width: width(500, 1040);
  }
  &:not(:last-child) {
    margin: 0 0 remhalf(20) 0;
    @include responsive(md) {
      margin: 0 0 rem(40);
    }
  }
  &:last-child {
    .p-home-type__itemNAme {
      color: #0f0304;
    }
  }
}

.p-home-type__link {
  display: block;
  transition: opacity 0.5s $transition-base;

  @include hover {
    opacity: 0.7;
  }
}
.p-home-type__figure {
  position: relative;
}

.p-home-type__itemEng {
  display: inline-block;
  color: $color-gold;
  text-align: center;
  position: absolute;
  top: remhalf(33);
  left: 50%;
  transform: translate(-50%, 0%);
  @include fonthalf(26, 28, 400, 100, rem);
  font-family: $font-family-english;
  @include responsive(md) {
    top: 28px;
    @include mfont(16, 20, 22, 400, 100, rem);
  }
  &:before {
    content: '';
    width: remhalf(68);
    height: 1px;
    background-color: $color-gold;
    position: absolute;
    top: 50%;
    left: remhalf(-78);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(57);
      left: rem(-66);
    }
  }
  &:after {
    content: '';
    width: remhalf(68);
    height: 1px;
    background-color: $color-gold;
    position: absolute;
    top: 50%;
    right: remhalf(-76);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(57);
      right: rem(-66);
    }
  }
}

.p-home-type__itemNAme {
  color: $color-white;
  @include fonthalf(28, 16, 400, 200, rem);
  text-align: center;
  position: absolute;
  bottom: remhalf(48);
  left: 50%;
  transform: translate(-50%, 0%);
  @include responsive(md) {
    @include mfont(16, 20, 25, 400, 200, rem);
    bottom: 31px;
    // bottom: rem(31);
  }
}
