/* p-reservation
----------------------------------------- */
@use '../../global' as *;

body {
  &.reservation {
    .p-banner {
      display: none;
    }
  }
  &.reservation-confirm {
    .p-banner {
      display: none;
    }
  }
}

.p-reservation {
  background-color: #231112;
  &.--confirm {
    background-color: #0f0304;
    .l-form__item--title {
      color: #fff;
      opacity: 0.3;
    }
    .l-form__detail {
      p {
        color: $color-white;
        @include fonthalf(24, 44, 400, 100, rem);
        font-family: $font-family-base02;
        @include responsive(md) {
          @include mfont(11, 16, 32, 400, 200, rem);
        }
      }
    }
    .l-form__list {
      margin: 0 0 rem(40);
      @include responsive(md) {
        margin: 0 0 rem(64);
      }
    }
    .l-form__agree {
      display: none;
    }
    .l-form__btnBlock {
      &.--back {
        input {
          margin: 0 0 rem(20);
          background-color: rgba(255, 255, 255, 0.3);
          @include responsive(md) {
            margin: 0 0 rem(32) auto;
          }
        }
        .c-button__arrow {
          display: none;
        }
      }
    }
  }
  &.--thanks {
    background-color: #0f0304;
  }
}

.p-reservation-banner {
  padding: rem(15) 0 remhalf(98);
  @include responsive(md) {
    padding: rem(12) 0 rem(108);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      width: rem(930);
      padding: 0;
      margin: 0 auto;
    }
  }
}

.p-reservation-banner__figure {
}
