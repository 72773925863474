/* collection
----------------------------------------- */
@use '../../global' as *;

body {
  &.collection {
    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-collection {
  background-color: #0f0304;
}

.p-collection-sec {
  padding: remhalf(100) 0;
  @include responsive(md) {
    padding: rem(100) 0;
  }
  &:first-of-type {
    padding: remhalf(40) 0 remhalf(100);
    @include responsive(md) {
      padding: 0 0 rem(100);
      margin: rem(-15) 0 0;
    }
  }
  &:last-child {
    @include responsive(md) {
      padding: rem(100) 0 rem(150);
    }
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1700px;
    }
  }
  &.--brown {
    background-color: #231112;
  }
}

.p-collection__inner {
  margin: remhalf(-12) 0 remhalf(35);
  padding: remhalf(68) remhalf(50) remhalf(77);
  position: relative;
  @include responsive(md) {
    padding: rem(60) rem(127) 0;
    margin: rem(-22) 0 0;
  }
  &:before {
    content: '';
    width: remhalf(85);
    height: 100%;
    background-image: url(../images/collection/collection_ornament_left_sp.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    @include responsive(md) {
      background-size: contain;
      width: rem(100);
      background-image: url(../images/collection/collection_ornament_left_pc.svg);
    }
  }
  &:after {
    content: '';
    width: remhalf(85);
    height: 100%;
    background-image: url(../images/collection/collection_ornament_right_sp.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0%, 0%);
    @include responsive(md) {
      background-size: contain;
      width: rem(100);
      background-image: url(../images/collection/collection_ornament_right_pc.svg);
    }
  }
}

.p-collection__list {
  @include flex(flex-start, flex-start, row);
  flex-wrap: wrap;
}

.p-collection__item {
  width: width(280, 600);
  @include responsive(md) {
    width: width(298, 1286);
  }
  &:not(:nth-child(2n)) {
    margin: 0 width(40, 600) width(35, 600) 0;
    @include responsive(md) {
      margin: 0 width(30, 1286) width(70, 1286) 0;
    }
  }
  &:not(:nth-child(4n)) {
    @include responsive(md) {
      margin: 0 width(30, 1286) width(70, 1286) 0;
    }
  }
  &:nth-child(2n) {
    margin: 0 0 width(35, 600) 0;
    @include responsive(md) {
      margin: 0 width(30, 1286) width(70, 1286) 0;
    }
  }
  &:nth-child(4n) {
    @include responsive(md) {
      margin: 0 0 width(70, 1286) 0;
    }
  }
}

.p-collection__link {
  display: block;
  transition: opacity 0.5s $transition-base;
  @include hover {
    opacity: 0.7;
  }
}

.p-collection__figure {
  @include img(280, 204);
  margin: 0 0 remhalf(14);
  @include responsive(md) {
    @include img(298, 224);
    height: rem(224);
    margin: 0 0 rem(14);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-collection__desc {
  color: $color-white;
  @include fonthalf(22, 40, 400, 100, rem);
  margin: 0 0 remhalf(18);
  display: block;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(10, 16, 32, 400, 200, rem);
    margin: 0 0 rem(25);
    @include line-clamp(3);
  }
}

.p-collection__number {
  color: $color-white;
  @include fonthalf(20, 30, 400, 200, rem);
  text-align: center;
  display: block;
  padding: 0 0 remhalf(19);
  border-bottom: remhalf(5) solid $color-white;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(10, 16, 32, 400, 200, rem);
    padding: 0 0 rem(16.5);
  }
}
