/* company
----------------------------------------- */
@use '../../global' as *;

body {
  &.company {
    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-company {
  background-color: #0f0304;
  color: $color-white;
  padding: remhalf(30) 0 remhalf(90);
  @include responsive(md) {
    padding: rem(5) 0 rem(100);
  }
}

.p-company-outline {
  *:not(h3) {
    font-family: $font-family-base02;
  }

  .p-hero__titleEng,
  .p-hero__titleJp {
    font-family: $font-family-base;
  }

  .p-common-deco-body {
    .p-common-deco-body__inner {
      padding-top: remhalf(200);

      @include responsive(md) {
        padding-top: rem(260);
      }
    }
  }

  table {
    border-collapse: collapse;
    margin: auto;

    * {
      @include fonthalf(22, 40, 400, 100, rem);

      @include responsive(md) {
        @include mfont(12, 16, 32, 400, 200, rem);
      }
    }

    tr {
      border-top: 1px solid rgba($color-tanzo-beige, 0.2);
      padding-top: rem(24);
      padding-bottom: rem(24);
      display: flex;
      flex-direction: column;

      @include responsive(md) {
        flex-direction: row;
      }
    }

    th {
      vertical-align: top;
      text-align: left;
      padding-bottom: rem(12);
      @include fonthalf(24, 32, 700, 200, rem);

      @include responsive(md) {
        padding-right: 1rem;
        width: width(180, 922);
        @include mfont(12, 16, 32, 700, 200, rem);
      }
    }

    td {
      vertical-align: top;
      @include responsive(md) {
        width: width(742, 922);
      }
    }
  }

  article {
    margin-top: rem(80);

    &:last-of-type {
      padding-bottom: rem(80);
    }
  }
}
