/* p-home-news
----------------------------------------- */
@use '../../global' as *;

.p-home-news--top {
  background-color: #000000;
  padding: remhalf(24) width(40, 780) remhalf(33);
  @include responsive(md) {
    padding: rem(30) rem(190);
  }
  time {
    @include fonthalf(18, 27, 400, 100, rem);
    color: $color-white;
    font-family: $font-family-base02;
    margin: 0 0 remhalf(3);
    transition: color 0.5s $transition-base;
    @include responsive(md) {
      margin: 0 1em 0 0;
      @include mfont(14, 14, 21, 400, 100, rem);
    }
  }
  .p-home-news__link {
    display: block;
    @include responsive(md) {
      @include flex(flex-start, center, row);
    }
    @include hover {
      time {
        color: $color-gold;
      }
      .p-home-news__title {
        color: $color-gold;
      }
    }
  }

  .p-home-news__title {
    transition: color 0.5s $transition-base;
    color: $color-white;
    @include fonthalf(22, 33, 400, 100, rem);
    font-family: $font-family-base02;
    text-decoration: underline;
    @include responsive(md) {
      @include mfont(14, 14, 21, 400, 100, rem);
    }
  }
}
