@use 'sass:math';
@use 'variables' as var;

// ===========================
// font
// ===========================
@mixin font($fsz: 16, $lh: 25, $fw: 400, $ls: 0.05, $uni: rem) {
  $linS: math.div($lh, $fsz);
  font-size: #{math.div($fsz, 10) * 0.625} + $uni;
  font-weight: $fw;
  line-height: $linS;
  letter-spacing: #{math.div($ls, 1000)} + 'em';
}

@mixin fonthalf($fszH: 16, $lhH: 25, $fwH: 400, $lsH: 0.05, $uniH: rem) {
  $linSH: math.div($lhH, $fszH);
  font-size: #{math.div($fszH, 10) * 0.625 * 0.5} + $uniH;
  font-weight: $fwH;
  line-height: $linSH;
  letter-spacing: #{math.div($lsH, 1000)} + 'em';
}

// ===========================
// max-font
// ===========================
@mixin mfont($fszm: 12, $fsz: 16, $lh: 25, $fw: 400, $ls: 0.05, $uni: rem, $px: px) {
  $linS: math.div($lh, $fsz);
  font-size: max + '(' + (#{math.div($fsz, 10) * 0.625} + $uni, $fszm + $px) + ')';
  font-weight: $fw;
  line-height: $linS;
  letter-spacing: #{math.div($ls, 1000)} + 'em';
}

// ===========================
// media query
// ===========================
@mixin responsive($breakpoint) {
  @media #{map-get(var.$breakpoints, $breakpoint)} {
    @content;
  }
}

// breakpoint 768px〜1023px
@mixin md {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

// breakpoint 768px〜1920px
@mixin lg {
  @media screen and (min-width: 768px) and (max-width: 1920px) {
    @content;
  }
}

// ===========================
// flex
// ===========================
@mixin flex($jc, $ai, $fd) {
  display: flex;
  flex-direction: $fd;
  align-items: $ai;
  justify-content: $jc;
}
// @include flex(flex-start, center, row);

// ===========================
// aspect(要素の縦横比)
// ===========================
// @mixin img($pt) {
@mixin img($width, $height) {
  position: relative;
  overflow: hidden;
  &::before {
    display: block;
    padding-top: round((($height / $width) * 100%) * 100) / 100;
    content: '';
  }
}
// @include img.img(500,500); → 縦横比 1:1

// ===========================
// clearfix(floatの解除)
// ===========================
@mixin clear {
  &::after {
    display: block;
    height: 0;
    clear: both;
    content: ' ';
  }
}

// ===========================
// 表示行数の制御
// ===========================
@mixin line-clamp($count: 3) {
  // 引数が数値以外だったらエラーを返す
  @if type-of($count) != 'number' {
    @error 'line-clampの引数は必ず数値で指定してください';
  }

  @if $count == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

//===============================
// hover動作が使えるデバイスかつ、
// ポインターがマウスポインターのデバイスのみhover効果を与える
//===============================
@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}
