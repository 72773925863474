/* l-block
----------------------------------------- */
@use '../global' as *;

.l-block-title {
  position: relative;
  padding: remhalf(21) 0 0;
  text-align: center;
  @include responsive(md) {
    padding: rem(21) 0 0;
  }
  &:before {
    content: '';
    background-image: url(../images/common/title_ornament.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: remhalf(84);
    height: remhalf(26);
    position: absolute;
    top: remhalf(-26);
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(84);
      height: rem(26);
      top: rem(-28);
    }
  }
}

.l-block-title__jp {
  @include fonthalf(36, 25, 400, 200, rem);
  text-align: center;
  display: block;
  color: $color-white;
  margin: 0 0 remhalf(-10);
  @include responsive(md) {
    @include mfont(20, 36, 25, 400, 200, rem);
    margin: 0 0 rem(12);
  }
}

.l-block-title__eng {
  color: $color-gold;
  text-align: center;
  display: inline-block;
  position: relative;
  @include fonthalf(20, 22, 400, 100, rem);
  font-family: $font-family-english;
  @include responsive(md) {
    @include mfont(12, 20, 22, 400, 100, rem);
  }
  &:before {
    content: '';
    width: remhalf(57);
    height: 1px;
    background-color: $color-gold;
    position: absolute;
    top: 50%;
    left: remhalf(-68);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(57);
      left: rem(-68);
    }
  }
  &:after {
    content: '';
    width: remhalf(57);
    height: 1px;
    background-color: $color-gold;
    position: absolute;
    top: 50%;
    right: remhalf(-68);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(57);
      right: rem(-68);
    }
  }
}
