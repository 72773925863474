/* simple
----------------------------------------- */
@use '../global' as *;

// 見出し画像がない場合の見出しと本文の間の余白
.l-simple-head {
  margin-top: 3rem;
  margin-bottom: 10rem;
  @include responsive(md) {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}

.l-simple-head-with-subtext {
  margin-top: 0;
}

.l-non-head-spacer {
  margin-top: rem(95);

  @include responsive(md) {
    margin-top: rem(100);
  }
}
