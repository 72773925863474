/* p-home-banner
----------------------------------------- */
@use '../../global' as *;

.p-home-banner {
  background-color: #000;
  .l-container {
    padding: 0 width(40, 780) remhalf(275);
  }
}

.p-home-banner__inner {
}

.p-home-banner__list {
}

.p-home-banner__item {
  &:not(:last-child) {
    margin: 0 0 remhalf(36);
  }
}

.p-home-banner__link {
  display: block;
  transition: opacity 0.5s $transition-base;
  @include hover {
    opacity: 0.7;
  }
}

.p-home-banner__figure {
}
