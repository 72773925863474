/* p-request
----------------------------------------- */
@use '../../global' as *;

body {
  &.request {
    .p-banner {
      display: none;
    }
  }
  &.request-confirm {
    .p-banner {
      display: none;
    }
  }
  &.thanks {
    .p-banner {
      display: none;
    }
  }
}

.p-request {
  background-color: #231112;
  &.--confirm {
    background-color: #0f0304;
    .l-form__item--title {
      color: #fff;
      opacity: 0.3;
    }
    .l-form__detail {
      p {
        color: $color-white;
        @include fonthalf(24, 44, 400, 100, rem);
        font-family: $font-family-base02;
        @include responsive(md) {
          @include mfont(11, 16, 32, 400, 200, rem);
        }
      }
    }
    .l-form__list {
      margin: 0 0 rem(40);
      @include responsive(md) {
        margin: 0 0 rem(64);
      }
    }
    .l-form__agree {
      display: none;
    }
    .l-form__btnBlock {
      &.--back {
        input {
          margin: 0 0 rem(20);
          background-color: rgba(255, 255, 255, 0.3);
          @include responsive(md) {
            margin: 0 0 rem(32) auto;
          }
        }
        .c-button__arrow {
          display: none;
        }
      }
    }
  }
  &.--thanks {
    background-color: #0f0304;
  }
}

.p-request-lead {
  padding: remhalf(30) 0 remhalf(116);
  @include responsive(md) {
    padding: rem(19) 0 rem(76);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      width: rem(1010);
      padding: 0 rem(80);
      min-width: 604px;
      max-width: 1010px;
      margin: 0 auto;
    }
  }
  .c-button__inner {
    @include responsive(md) {
      width: rem(590);
      min-width: 300px;
      margin: rem(-50) 0 0 auto;
    }
    .c-button {
      @include responsive(md) {
        width: rem(457);
        margin: 0 auto 0 0;
        padding: rem(12) 0;
        min-width: 300px;
      }
      .c-button__arrow {
        @include responsive(md) {
          width: rem(22);
          height: rem(14);
          right: rem(15);
          top: calc(50% + 0.1rem);
        }
      }
    }
  }
}

.p-request-lead__title {
  border-top: 1px solid #8f7f58;
  border-bottom: 1px solid #8f7f58;
  padding: remhalf(19) 0 remhalf(28);
  @include fonthalf(30, 56, 400, 200, rem);
  color: $color-white;
  text-align: center;
  @include responsive(md) {
    width: rem(850);
    min-width: 540px;
    margin: 0 auto;
    padding: rem(18) 0 rem(22);
    @include mfont(12, 26, 50, 400, 200, rem);
    text-align: left;
  }
}

.p-request-lead__content {
  padding: rem(20) 0 rem(17);
  @include responsive(md) {
    width: rem(850);
    min-width: 540px;
    margin: 0 auto;
    padding: rem(28) 0 0;
    @include flex(space-between, flex-start, row-reverse);
  }
}

.p-request-lead__text {
  @include fonthalf(22, 40, 400, 100, rem);
  font-family: $font-family-base02;
  color: $color-white;
  margin: 0 0 rem(22);
  @include responsive(md) {
    width: width(590, 800);
    @include mfont(11, 16, 32, 400, 200, rem);
    margin: 0;
  }
}

.p-request-lead__figure {
  width: width(380, 700);
  margin: 0 auto;
  @include responsive(md) {
    width: width(227, 800);
    margin: 0 rem(33) 0 0;
  }
}

.p-request-form {
  background-color: #0f0304;
  padding: remhalf(50) 0 remhalf(168);
  @include responsive(md) {
    padding: rem(31) 0 rem(133);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 960px;
      margin: 0 auto;
    }
  }
}

.p-request-form__progress {
  padding: 0 remhalf(50);
  @include responsive(md) {
    padding: 0 rem(24);
  }
}

.p-request-form__progressInner {
  @include flex(space-between, center, row);
}

.p-request-form__progress-text {
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.3);
  @include fonthalf(26, 76, 400, 200, rem);
  text-align: center;
  font-family: $font-family-base02;
  display: block;
  @include responsive(md) {
    @include mfont(16, 20, 76, 400, 200, rem);
  }
  &:not(:last-child) {
    margin: 0 remhalf(17) 0 0;
    @include responsive(md) {
      margin: 0 rem(20) 0 0;
    }
  }
  &:last-child {
    margin: 0 0 0 remhalf(17);
    @include responsive(md) {
      margin: 0 0 0 rem(20);
    }
  }
  &:nth-child(3) {
    margin: 0 remhalf(17);
    @include responsive(md) {
      margin: 0 rem(20);
    }
  }
  &.--active {
    color: #fff;
  }
}

.p-request-form__progress-line {
  width: width(88, 300);
  height: 1px;
  background-color: $color-white;
  display: block;
  opacity: 0.3;
  @include responsive(md) {
    min-width: 180px;
    width: rem(240);
  }
  &.--active {
    opacity: 1;
  }
}
