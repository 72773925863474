/* p-ring-summary
----------------------------------------- */
@use '../../global' as *;

.p-ring-summary {
  padding: remhalf(63) 0 0;
  position: relative;
  @include responsive(md) {
    padding: rem(13) 0 0;
  }
  &:before {
    content: '';
    background-color: #0f0304;
    width: 100%;
    height: remhalf(63);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      height: rem(13);
    }
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1446px;
    }
  }
}

.p-ring-summary__inner {
  position: relative;
  &:before {
    @include responsive(md) {
      content: '';
      background-color: #0f0304;
      margin: 0 calc(50% - 50vw);
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  &.--pt100 {
    @include responsive(md) {
      padding: rem(100) 0 0;
    }
  }
  &.--flex {
    @include responsive(md) {
      padding: 0 0 rem(100);
      @include flex(space-between, flex-start, row);
    }
    .p-ring-summary__block {
      @include responsive(md) {
        width: width(629, 1286);
      }
    }
  }
  .c-button__inner {
    position: relative;
    @include responsive(md) {
      padding: rem(70) 0 0;
    }
    &:before {
      content: '';
      background-color: #0f0304;
      margin: 0 calc(50% - 50vw);
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .p-ring-summary__video-sec {
    @include responsive(md) {
      display: none;
    }
  }
}

.p-ring-summary__block {
  padding: 0 0 remhalf(100);
  position: relative;
  @include responsive(md) {
    padding: 0 0 rem(30);
  }
  &.--flex-column {
    @include responsive(md) {
      @include flex(flex-start, flex-start, column);
    }
  }
  &.--pt100-sp {
    padding: rem(50) 0;
    @include responsive(md) {
      padding: 0 0 rem(30);
    }
  }
  &.--pt100 {
    padding: rem(50) 0;
    @include responsive(md) {
      padding: rem(100) 0 rem(29.5);
    }
  }
  &.--pb100 {
    @include responsive(md) {
      padding: 0 0 rem(100);
    }
  }
  &:before {
    content: '';
    background-color: #0f0304;
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @include responsive(md) {
      display: none;
    }
  }
  &.p-ring-summary__block--feature {
    @include responsive(md) {
      @include flex(flex-start, flex-start, column-reverse);
      padding: 0 0 rem(32.5);
    }
    .p-ring-summary__list {
      @include responsive(md) {
        margin: min(-8.7%, -61px) 0 0;
        // margin: width(-112, 1286) 0 0;
        // margin: rem(-112) 0 0;
      }
    }
    .p-ring-summary__item {
      &:first-child {
        .p-ring-summary__desc {
          @include responsive(md) {
            height: 10.2%;
            min-height: 40px;
            margin: 0 0 rem(67);
          }
        }
      }
    }
    .p-ring-summary__bannerLink {
      @include responsive(md) {
        width: width(629, 1286);
        margin: 0 0 0 auto;
      }
    }
  }
  &.p-ring-summary__block--carryon {
    @include responsive(md) {
      padding: 0;
      @include flex(flex-start, flex-start, column-reverse);
    }
    .p-ring-summary__list {
      @include responsive(md) {
        @include flex(space-between, flex-start, row);
      }
      .p-ring-summary__item {
        @include responsive(md) {
          width: width(299, 629);
        }
        &:nth-child(n + 3) {
          @include responsive(md) {
            display: none;
          }
        }
      }
    }
    .p-ring-summary__bannerLink {
      @include responsive(md) {
        width: 100%;
        margin: 0 0 rem(31);
      }
    }
  }
  &.p-ring-summary__block--remake {
    padding: remhalf(100) 0;
    @include responsive(md) {
      padding: 0;
      @include flex(flex-start, flex-start, column);
    }
    .p-ring-summary__list {
      @include responsive(md) {
        @include flex(space-between, flex-start, row);
      }
      .p-ring-summary__item {
        @include responsive(md) {
          width: width(299, 629);
        }
        &:nth-child(n + 3) {
          @include responsive(md) {
            display: none;
          }
        }
      }
    }
    .p-ring-summary__bannerLink {
      @include responsive(md) {
        width: 100%;
        margin: rem(31) 0 0;
      }
    }
  }
  &.p-ring-summary__block--proposal,
  &.p-ring-summary__block--entryinfamily {
    .p-ring-summary__blockWrap {
      .p-ring-summary__bannerLink {
        @include responsive(md) {
          margin: -19.4% auto 0 0;
        }
      }
      .p-ring-summary__list {
        @include responsive(md) {
          @include flex(flex-start, flex-start, row-reverse);
        }
        .p-ring-summary__item {
          &:first-child {
            @include responsive(md) {
              margin: 0 0 rem(26) width(30, 1286);
            }
            .p-ring-summary__desc {
              @include responsive(md) {
                height: 10.2%;
                min-height: 40px;
                margin: 0 0 rem(67);
              }
            }
          }
          &:nth-child(2) {
            @include responsive(md) {
              margin: 0 0 0 width(30, 1286);
            }
          }
          &:nth-child(3) {
            @include responsive(md) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &.p-ring-summary__block--afterservice {
    @include responsive(md) {
      @include flex(flex-start, flex-start, column-reverse);
    }
    .p-ring-summary__item {
      &:first-child {
        .p-ring-summary__desc {
          @include responsive(md) {
            height: 10.2%;
            min-height: 40px;
            margin: 0 0 rem(67);
          }
        }
      }
    }
    .p-ring-summary__bannerLink {
      @include responsive(md) {
        margin: 0 0 -17.4% auto;
      }
    }
    &.--flex-column {
      @include responsive(md) {
        @include flex(flex-start, flex-start, column);
      }
    }
  }
  &.p-ring-summary__block--about {
    .p-ring-summary__blockWrap {
      @include responsive(md) {
        @include flex(flex-start, flex-start, column-reverse);
      }
      .p-ring-summary__list {
        @include responsive(md) {
          margin: min(-8.7%, -61px) 0 0;
        }
      }
      .p-ring-summary__bannerLink {
        @include responsive(md) {
          margin: 0 0 0 auto;
        }
      }
    }
    .p-ring-summary__item {
      &:first-child {
        .p-ring-summary__desc {
          @include responsive(md) {
            height: 10.2%;
            min-height: 40px;
            margin: 0 0 rem(67);
          }
        }
      }
    }
    .p-ring-summary__list {
      &.u-only-pc {
        .p-ring-summary__item {
          &:first-child {
            .p-ring-summary__desc {
              @include responsive(md) {
                margin: 0 0 rem(35);
              }
            }
          }
        }
      }
    }
  }
  &.p-ring-summary__block--entryinfamily {
    padding: rem(50) 0;
    @include responsive(md) {
      padding: 0 0 rem(30);
    }
  }
  &.p-ring-summary__block--ordermade {
    padding: rem(50) 0;
    @include responsive(md) {
      padding: 0 0 rem(100);
    }
    .p-ring-summary__blockWrap {
      @include responsive(md) {
        @include flex(flex-start, flex-start, column-reverse);
      }
      .p-ring-summary__list {
        @include responsive(md) {
          margin: min(-8.7%, -61px) 0 0;
        }
        .p-ring-summary__item {
          &:first-child {
            .p-ring-summary__desc {
              @include responsive(md) {
                height: 10.2%;
                min-height: 40px;
                margin: 0 0 rem(67);
              }
            }
          }
        }
      }
      .p-ring-summary__bannerLink {
        @include responsive(md) {
          margin: 0 0 0 auto;
        }
      }
    }
  }
}

.p-ring-summary__list {
  border-right: 1px solid #8f7f58;
  border-left: 1px solid #8f7f58;
  padding: 0 remhalf(50);
  @include flex(flex-start, flex-start, row);
  flex-wrap: wrap;
  @include responsive(md) {
    @include flex(flex-start, flex-end, row);
    border-right: none;
    border-left: none;
    padding: 0;
  }
  &.u-only-pc {
    display: none;
    @include responsive(md) {
      @include flex(flex-start, flex-start, row);
    }
    .p-ring-summary__item {
      &:nth-child(n + 4) {
        @include responsive(md) {
          display: block;
        }
      }
      &:first-child {
        @include responsive(md) {
          width: width(299, 1286);
        }
        .p-ring-summary__figure {
          @include responsive(md) {
            @include img(299, 224);
          }
        }
        .p-ring-summary__desc {
          @include responsive(md) {
            height: 10.2%;
            min-height: 40px;
            margin: 0 0 rem(35);
          }
        }
      }
    }
  }
}

.p-ring-summary__item {
  width: width(140, 298);
  &:first-child {
    @include responsive(md) {
      width: width(627, 1286);
    }
    .p-ring-summary__figure {
      @include responsive(md) {
        @include img(627, 478);
      }
    }
  }
  &:not(:first-child) {
    @include responsive(md) {
      width: width(299, 1286);
      margin: 0 0 0 width(30, 1286);
    }
    .p-ring-summary__figure {
      @include responsive(md) {
        @include img(299, 224);
      }
    }
  }
  &:nth-child(n + 4) {
    @include responsive(md) {
      display: none;
    }
  }
  &:not(:nth-child(2n)) {
    margin: 0 width(18, 298) width(15, 298) 0;
    @include responsive(md) {
      margin: inherit;
    }
  }
  &:nth-child(2n) {
    margin: 0 0 width(15, 298);
    @include responsive(md) {
      margin: 0 0 0 width(30, 1286);
    }
  }
  &:nth-child(3) {
    @include responsive(md) {
      margin: 0 0 0 width(30, 1286);
    }
  }
}

.p-ring-summary__link {
  display: block;
  transition: opacity 0.5s $transition-base;
  &:hover {
    opacity: 0.7;
  }
}

.p-ring-summary__figure {
  @include img(280, 204);
  margin: 0 0 remhalf(14);
  @include responsive(md) {
    margin: 0 0 rem(14);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-ring-summary__desc {
  @include fonthalf(22, 40, 400, 100, rem);
  margin: 0 0 remhalf(18);
  color: $color-white;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(10, 16, 32, 400, 200, rem);
    margin: 0 0 rem(35);
  }
}

.p-ring-summary__number {
  @include fonthalf(20, 30, 400, 200, rem);
  padding: 0 0 remhalf(19);
  color: $color-white;
  font-family: $font-family-base02;
  text-align: center;
  border-bottom: remhalf(5) solid $color-white;
  display: block;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
    border-bottom: rem(5) solid $color-white;
    padding: 0 0 rem(16.5);
  }
}

.p-ring-summary__text {
  color: #bfb7a3;
  text-align: center;
  @include fonthalf(24, 42, 400, 200, rem);
  font-family: $font-family-base02;
  padding: remhalf(19) 0 0;
  border-right: 1px solid #8f7f58;
  border-left: 1px solid #8f7f58;
  @include responsive(md) {
    display: none;
  }
}

.p-ring-summary__bannerLink {
  display: block;
  margin: width(34, 700) 0 0;
  transition: opacity 0.5s $transition-base;
  @include responsive(md) {
    width: width(629, 1286);
    min-width: 344px;
    position: relative;
  }

  &:hover {
    opacity: 0.7;
  }
}

.p-ring-summary__banner {
  @include responsive(md) {
    @include img(629, 224);
  }
  img {
    @include responsive(md) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      font-family: 'object-fit: cover;';
      object-fit: cover;
    }
  }
}

.p-ring-summary__block--carryon {
}

.p-ring-summary__video-sec {
  height: 100vh;
  position: relative;
}

.p-ring-summary__blockWrap {
  @include responsive(md) {
    padding: 0 0 rem(32.5);
  }
  &.--flex-row-reverse {
    .p-ring-summary__list {
      @include responsive(md) {
        @include flex(flex-start, flex-start, row-reverse);
      }
      .p-ring-summary__item {
        &:first-child {
          @include responsive(md) {
            margin: 0 0 1.625rem width(30, 1286);
          }
          .p-ring-summary__desc {
            @include responsive(md) {
              height: 10.2%;
              min-height: 40px;
              margin: 0 0 4.1875rem;
            }
          }
        }
        &:nth-child(2) {
          @include responsive(md) {
            margin: 0 0 0 width(30, 1286);
          }
        }
        &:nth-child(3) {
          @include responsive(md) {
            margin: 0;
          }
        }
      }
    }
    .p-ring-summary__bannerLink {
      @include responsive(md) {
        margin: -19.4% auto 0 0;
      }
    }
  }
}
