// ===========================
// color
// ===========================

// transition
$transition-base: cubic-bezier(0.25, 0.1, 0.25, 1);

// 基本カラー
$color-text: #333333;
$color-white: #f9f8f5;
$color-gold: #8f7f58;
$color-background: #f6f6f6;

// Figma で付けられていた名前
$color-deep-red: #1f0202;
$color-tanzo-dark-brown: #0f0304;
$color-tanzo-dark-brown-2: #231112;
$color-tanzo-beige: #bfb7a3;
$color-tanzo-black: #202020;

// 下層ページの見出しの下につく線の色
$color-border: rgba($color-tanzo-beige, 0.2);

// ===========================
// font
// ===========================
$font-family-base: 'Noto Serif JP', serif;
// R - 400
// M - 500
// B - 700

$font-family-base02: 'Noto Sans JP', sans-serif;

$font-family-english: 'adobe-garamond-pro', serif;
// R - 400
// SB - 600
// B - 700

// ===========================
// config
// ===========================

// ブレイクポイント(モバイルファースト)
$breakpoints: (
  'xs': 'screen and (min-width: 369px)',
  'sm': 'screen and (min-width: 576px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1024px)',
  'lg-2': 'screen and (min-width: 1025px)',
  'xl': 'screen and (min-width: 1280px)',
  'xxl': 'screen and (min-width: 1440px)',
  'xxl2': 'screen and (min-width: 1600px)',
) !default;

$xs: 369px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$lg-2: 1025px;
$xl: 1280px;
$xxl: 1440px;
$xxl2: 1600px;
