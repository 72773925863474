/* home-kv
----------------------------------------- */
@use '../../global' as *;

body {
  &.multilingual {
    .p-home-kv__video {
      transition: opacity 1s $transition-base;
    }
  }
}

.p-home-kv {
  height: 100svh;
  &.is-anim {
    .p-home-kv__titleText {
      transform: translate(0%, 0%);
    }
  }
}

.p-home-kv__video {
  height: 100%;
  width: 100%;

  video {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  &.--kv {
    opacity: 1;
    &.js-video-change {
      &.is-active {
        opacity: 0;
      }
    }
  }
  &.--about {
    opacity: 0;
    &.js-video-change {
      &.is-active {
        opacity: 1;
      }
    }
  }
}

.p-home-kv__title {
  position: absolute;
  bottom: 13vh;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  @include responsive(md) {
    bottom: 38vh;
    transform: translate(0%, 0%);
    right: widthvw(220, 1920);
    width: auto;
    left: auto;
  }
}

.p-home-kv__titleTextWrap {
  display: block;
  overflow: hidden;
  &:nth-child(2) {
    .p-home-kv__titleText {
      transition-delay: 0.5s;
    }
  }
}

.p-home-kv__titleText {
  display: block;
  color: $color-white;
  @include fonthalf(55, 80, 400, 100, rem);
  text-align: center;
  transform: translate(0%, 100%);
  transition: transform 1.1s $transition-base;
  @include responsive(md) {
    @include mfont(32, 50, 76, 400, 200, rem);
  }
}

.p-scroll-line {
  position: absolute;
  right: rem(15);
  bottom: 0;
  z-index: 1;
  width: rem(1);
  height: rem(59);
  background: #8f8058;
  transition: all 1.4s;
  @include responsive(md) {
    height: 80px;
    right: 126px;
  }
  &:after {
    position: absolute;
    display: block;
    width: rem(2.5);
    height: rem(27);
    background: #8f8058;
    content: '';
    animation: circlemove 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      left: calc(50% - 0.03rem);
      animation: circlemovePc 1.6s ease-in-out infinite, cirlemovehide 1.6s ease-out infinite;
      height: 30px;
      width: 5px;
    }
  }
}

@keyframes circlemove {
  0% {
    bottom: rem(32);
  }
  100% {
    bottom: rem(-5);
  }
}

@keyframes circlemovePc {
  0% {
    bottom: 50px;
  }
  100% {
    bottom: -2px;
  }
}

@keyframes cirlemovehide {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

.p-scroll-line__text {
  position: absolute;
  top: rem(-68);
  left: rem(6);
  color: #8f7f58;
  vertical-align: top;
  transform: scale(-1);
  transform-origin: left bottom;

  @include fonthalf(18, 26, 400, 100, rem);
  writing-mode: vertical-rl;
  @include responsive(md) {
    @include mfont(12, 14, 20, 400, 100, rem);
    writing-mode: inherit;
    transform: scale(1);
    top: 0;
    right: -60px;
    left: auto;
  }
}
