/* p-tanzo-seihou
----------------------------------------- */
@use '../../global' as *;

.p-seihou-container {
  .p-seihou-compare-table {
    width: 100%;
    border-collapse: collapse;
    margin: rem(100) auto;

    th,
    td {
      border: 1px solid $color-border;
      padding: rem(16);
    }

    .p-seihou-compare-table__head {
      background-color: $color-tanzo-dark-brown-2;
    }

    .p-seihou-compare-table__this {
      background-color: rgba($color-gold, 0.8);
    }
  }

  .p-seihou-articles {
    display: flex;
    flex-direction: column;
    gap: rem(80);

    h4 {
      border-bottom: 1px solid $color-border;

      &::before {
        content: '■';
        padding-right: rem(4);
        color: $color-gold;
      }
    }
  }

  .p-seihou-test-results {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    justify-content: center;
    align-items: center;

    @include responsive(md) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .p-seihou-test-box {
    .p-seihou-test-box__item {
      display: flex;
      gap: rem(16);

      img {
        aspect-ratio: 200/150;
      }

      figcaption {
        @include fonthalf(24, 40, 400, 100, rem);
        margin-top: rem(8);

        @include responsive(md) {
          @include mfont(12, 12, 14, 400, 200, rem);
        }
      }

      .p-seihou-test-box__cut {
        display: none !important;

        @media screen and (min-width: 1200px) {
          display: block !important;
        }
      }
    }

    .p-seihou-test-box__arrow {
      position: relative;
      display: inline-block;
      padding: rem(10);

      &::before {
        content: '';
        width: rem(24);
        height: rem(24);
        border-top: solid 3px $color-gold;
        border-right: solid 3px $color-gold;
        position: absolute;
        left: -50%;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  .p-seihou-test-caption-box {
    position: relative;
    background-color: $color-tanzo-dark-brown-2;
    border: 3px solid $color-gold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .p-seihou-test-caption {
    padding: remhalf(100) width(80, 768) remhalf(150);
    @include responsive(md) {
      padding: rem(80) width(190, 1920) rem(130);
    }

    &::before {
      left: remhalf(9);
      content: '';
      position: absolute;
      display: block;
      border: 1px solid rgba($color-gold, 0.8);
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
    }

    &::after {
      right: remhalf(9);
      background-color: #3d2d23;
    }
  }

  .p-seihou-test-head {
    text-align: center;
    margin-bottom: 1rem;
    font-family: $font-family-base;

    &::after {
      content: '';
      display: block;
      width: rem(100);
      height: 1px;
      background-color: $color-gold;
      margin: rem(16) auto;
    }
  }

  .p-seihou-test-results-container.p-possession-section__aside-image {
    margin-top: rem(40);
    gap: rem(40);
    flex-direction: column;

    @include responsive(md) {
      flex-direction: row;
    }
  }

  .p-seihou-align-center {
    align-items: center;
  }

  .p-seihou-inner-head {
    font-family: $font-family-base;

    p {
      font-family: $font-family-base;
      margin: remhalf(14) 0 0;
      display: block;
      color: #fff;
      @include fonthalf(22, 31.6, 400, 200, rem);
      text-align: center;
      @include responsive(md) {
        margin: rem(14) 0 0;
        @include mfont(15, 22, 32, 400, 200, rem);
      }
    }
  }

  .p-seihou-faq-list__columns {
    .p-faq-card {
      .p-faq-card__label {
        font-family: $font-family-base;
      }
    }
  }
}
