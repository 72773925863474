/* p-news
----------------------------------------- */
@use '../../global' as *;

.p-news {
  &.p-common {
    .p-news-container {
      &.l-container {
        width: 100%;
        max-width: 1360px;
      }
    }
  }

  .p-common-deco-body__inner {
    @include fonthalf(24, 48, 400, 200, rem);
    @include responsive(md) {
      @include mfont(12, 16, 32, 400, 200, rem);
    }

    * {
      font-family: $font-family-base02;
    }

    .p-news-articles {
      background-color: $color-tanzo-black;
    }
  }

  .p-news-articles {
    padding-right: rem(30);
    padding-left: rem(30);

    .p-news-articles__item {
      .p-news-articles__article {
        padding: rem(30);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: rem(20);

        @include responsive(md) {
          grid-column-gap: rem(30);
          grid-template-columns: rem(100) 1fr;
        }
      }

      .p-news-tag {
        p {
          padding-bottom: rem(10);
          display: inline;

          &::after {
            content: '';
            height: 1px;
            background-color: $color-white;
            display: block;
            width: rem(96);
          }
        }
      }

      .p-news-title {
        word-break: initial;
      }
    }

    :not(:first-of-type).p-news-articles__item {
      .p-news-articles__article {
        border-top: 1px solid $color-tanzo-dark-brown;
      }
    }
  }

  .p-news-pager-container {
    padding-right: rem(30);
    padding-left: rem(30);
  }
}
