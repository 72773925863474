/* swiper
----------------------------------------- */
@use '../global' as *;

.e-swiper {
  margin-bottom: 0 !important;
  .swiper-pagination {
    position: absolute;
    bottom: calc(9.6% + 3rem) !important;
    left: 50% !important;
    z-index: 2;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    padding-right: rem(80);
    padding-left: rem(80);
    text-align: left;
    transform: translate(-50%, 0%);

    @include responsive(md) {
      bottom: rem(72) !important;
      padding-right: rem(20);
      padding-left: rem(20);
    }
    .swiper-pagination-bullet {
      width: 8.2px;
      height: 8.2px;
      margin: 0 7px !important;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: #fff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: rgba(0, 0, 0, 0);
      opacity: 1;
    }
  }
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      min-height: 720px;
      font-family: 'object-fit: cover;';

      object-position: bottom;
      object-fit: cover;
      @include responsive(md) {
        min-height: unset;
      }
    }
  }
}
