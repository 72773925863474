/* footer
----------------------------------------- */
@use '../global' as *;

.l-footer {
  background-color: #231112;
  padding: remhalf(106) 0 remhalf(371);
  position: relative;
  @include responsive(md) {
    padding: 61px 0 rem(234);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include md {
      max-width: 1608px;
      padding: 0 rem(160);
      display: block;
    }
    @include responsive(lg) {
      max-width: 1608px;
      padding: 0 rem(160);
      display: flex;
      justify-content: space-between;
    }
  }
}

.l-footer__returnTop {
  width: remhalf(96);
  height: remhalf(96);
  position: absolute;
  top: remhalf(-48);
  right: remhalf(34);
  @include responsive(md) {
    width: 58px;
    height: 58px;
    top: -29px;
    right: rem(130);
  }
  a {
    display: block;
  }
}

.l-footer__info {
  @include md {
    display: flex;
    flex-direction: column;
    margin: 0 0 rem(120);
  }
  @include responsive(lg) {
    flex-shrink: 0;
    width: width(440, 1288);
    display: flex;
    flex-direction: column;
    margin: 0 rem(64) 0 0;
  }
}

.l-footer__logo {
  width: remhalf(307);
  margin: 0 auto;
  @include responsive(md) {
    width: max(10.5625rem, 120px);
    margin: 0 auto rem(152) 0;
  }
}

.l-footer__logoLink {
  display: block;
}

.l-footer__infoBottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  @include responsive(md) {
    position: static;
    transform: translate(0%, 0%);
  }
}

.l-footer__infoSocial {
  display: block;
  width: remhalf(80);
  height: remhalf(80);
  margin: 0 auto;
  transition: opacity 0.5s $transition-base;
  @include responsive(md) {
    width: max(3.125rem, 32px);
    height: max(3.125rem, 32px);
    margin: 0 auto 0 0;
  }
  @include hover {
    opacity: 0.7;
  }
}

.l-footer__infoList {
  margin: remhalf(60) 0 remhalf(68);
  @include flex(center, center, row);
  @include responsive(md) {
    margin: rem(16) 0 rem(9);
    @include flex(flex-start, center, row);
  }
}

.l-footer__infoItem {
  &:not(:last-child) {
    margin: 0 remhalf(68) 0 0;
    @include responsive(md) {
      margin: 0 rem(40) 0 0;
    }
  }
}

.l-footer__infoLink {
  display: block;
  @include fonthalf(20, 20, 400, 200, rem);
  color: $color-white;
  font-family: $font-family-base02;
  transition: color 0.5s $transition-base;
  @include responsive(md) {
    @include mfont(10, 12, 20, 400, 200, rem);
  }
  @include hover {
    color: $color-gold;
  }
}

.l-footer__copy {
  display: block;
  @include fonthalf(14, 19, 400, 200, rem);
  font-family: $font-family-base02;
  text-align: center;
  color: $color-white;
  margin: 0 0 remhalf(44);
  @include responsive(md) {
    @include mfont(10, 10, 20, 400, 200, rem);
    margin: 0;
    text-align: left;
  }
}

.l-footer__nav {
  margin: remhalf(98) 0 0;
  @include responsive(md) {
    // width: width(760, 1288);
    margin: 0;
    @include flex(flex-start, flex-start, row);
  }
}

.l-footer__list {
  &:not(:last-child) {
    @include responsive(md) {
      margin: 0 rem(50) 0 0;
    }
  }
  a {
    transition: color 0.5s $transition-base;
    @include hover {
      color: $color-gold;
    }
    &.l-footer__itemTitle {
      .l-footer__itemTitle--text {
        transition: color 0.5s $transition-base;
      }
      @include hover {
        .l-footer__itemTitle--text {
          color: $color-gold;
        }
      }
    }
  }
}

.l-footer__item {
  border-bottom: 1px solid rgba(191, 183, 163, 0.2);
  @include responsive(md) {
    border-bottom: none;
  }
  &:first-child {
    border-top: 1px solid rgba(191, 183, 163, 0.2);
    @include responsive(md) {
      border-top: none;
    }
  }
  &:not(:last-child) {
    @include responsive(md) {
      margin: 0 0 rem(29);
    }
  }
  p {
    @include responsive(md) {
      pointer-events: none;
      white-space: nowrap;
    }
  }
}

.l-footer__itemTitle {
  display: block;
  padding: remhalf(24) 0;
  cursor: pointer;
  position: relative;
  @include responsive(md) {
    padding: 0;
    margin: 0 0 rem(10);
  }
  &.--open {
    .l-footer__itemTitle--toggle {
      &:after {
        transform: translate(-50%, -50%) rotateZ(90deg);
      }
    }
  }
}

.l-footer__itemTitle--text {
  color: $color-white;
  @include fonthalf(22, 33, 400, 200, rem);
  display: block;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(11, 14, 20, 400, 200, rem);
  }
}

.l-footer__itemTitle--toggle {
  position: absolute;
  top: 50%;
  right: remhalf(28);
  transform: translate(0%, -50%);
  width: remhalf(24);
  height: remhalf(24);
  display: block;
  @include responsive(md) {
    display: none;
  }
  &:before {
    content: '';
    width: remhalf(24);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    transition: transform 0.5s $transition-base;
    content: '';
    width: 1px;
    height: remhalf(24);
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.l-footer__subList {
  display: none;
  padding: 0 0 remhalf(24);
  @include responsive(md) {
    display: block;
    padding: 0;
  }
}

.l-footer__subItem {
}

.l-footer__subLink {
  display: block;
  @include font(10, 26.7, 400, 200, rem);
  color: $color-white;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(10, 12, 32, 400, 200, rem);
  }
}

.--small {
}
