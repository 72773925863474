/* p-home-voice
----------------------------------------- */
@use '../../global' as *;

.p-home-voice {
  @include md {
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    padding: 0 rem(140);
    background-color: #231112;
    position: relative;
  }
  @include responsive(lg) {
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    padding: 0 rem(244);
    background-color: #231112;
    position: relative;
  }
  &:before {
    @include responsive(md) {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background-color: #8f7f58;
      width: 1px;
      left: rem(10);
    }
  }
  &:after {
    @include responsive(md) {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background-color: #8f7f58;
      width: 1px;
      right: rem(10);
    }
  }
}

.p-home-voice__top {
  @include responsive(md) {
    background-image: url(../images/home/brown_bg_top_pc.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: rem(126);
    position: absolute;
    top: rem(-126);
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.p-home-voice__inner {
  margin: remhalf(112) 0 0;
  @include responsive(md) {
    margin: rem(100) 0 0;
  }
  .splide__arrow {
    display: none;
    @include responsive(md) {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background: rgba(255, 255, 255, 0);
      opacity: 1;
      width: 20px;
      height: 28px;
      background-image: url(../images/home/slider_arrow.svg);
    }
    &.splide__arrow--prev {
      left: rem(-58);
    }
    &.splide__arrow--next {
      transform: translateY(-50%) scaleX(-1);
      right: rem(-58);
    }
    svg {
      display: none;
    }
  }
}

.splide {
}

.p-home-voice__slider {
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    margin: 0 0 rem(40);
  }
}

.splide__track {
}

.p-home-voice__sliderList {
}

.splide__list {
}

.p-home-voice__sliderItem {
  padding: 0 0 remhalf(32);
  border-bottom: remhalf(5) solid $color-white;
  @include responsive(md) {
    padding: 0 0 rem(20);
    border-bottom: rem(5) solid $color-white;
  }
}

.splide__slide {
}

.p-home-voice__sliderFigure {
  @include img(330, 233);
  margin: 0 0 remhalf(21);
  @include responsive(md) {
    @include img(330, 233);
    margin: 0 0 rem(21);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-home-voice__sliderItemShop {
  color: $color-white;
  position: relative;
  @include fonthalf(20, 30, 400, 200, rem);
  font-family: $font-family-base02;
  padding: 0 0 remhalf(13);
  display: block;
  margin: 0 0 remhalf(15);
  @include responsive(md) {
    @include mfont(12, 14, 14, 400, 200, rem);
    margin: 0 0 rem(12);
    padding: 0 0 rem(17);
  }
  &:before {
    content: '';
    width: remhalf(60);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    left: 0;
    bottom: 0;
    @include responsive(md) {
      width: rem(60);
    }
  }
}

.p-home-voice__sliderItemDesc {
  @include line-clamp(2);
  @include fonthalf(24, 48, 400, 200, rem);
  color: $color-white;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}
