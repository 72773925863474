/* p-shop
----------------------------------------- */
@use '../../global' as *;

body {
  &.shop {
    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-shop {
  background-color: #0f0304;
  padding: 0 0 rem(50);
  @include responsive(md) {
    padding: 0 0 rem(100);
  }
}

.p-shop-nav {
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
    }
  }
}

.p-shop-nav__nav {
  padding: rem(20) 0 remhalf(75);
  @include responsive(md) {
    padding: rem(22) 0 rem(76);
  }
}

.p-shop-nav__list {
  @include flex(flex-start, flex-start, row);
  flex-wrap: wrap;
  gap: rem(20) rem(40);
  @include responsive(md) {
    gap: 0;
    @include flex(center, flex-start, row);
  }
}

.p-shop-nav__item {
  white-space: nowrap;
  &:not(:last-child) {
    @include responsive(md) {
      margin: 0 rem(50) 0 0;
    }
  }
  &.--one {
    width: width(178, 700);
    @include responsive(md) {
      width: inherit;
    }
  }
  &.--two {
    width: width(120, 700);
    @include responsive(md) {
      width: inherit;
    }
  }
  &.--three {
    width: width(235, 700);
    @include responsive(md) {
      width: inherit;
    }
  }
}

.p-shop-nav__link {
  color: #fff;
  @include fonthalf(24, 34, 400, 200, rem);
  padding: 0 0 0 remhalf(38);
  position: relative;
  display: block;
  @include responsive(md) {
    padding: 0 0 0 rem(27);
    @include mfont(11, 16, 23, 400, 200, rem);
  }
  &:before {
    content: '';
    width: remhalf(30);
    height: remhalf(30);
    background-image: url(../images/shop/nav_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(20);
      min-width: 9px;
      height: rem(20);
      min-height: 9px;
    }
  }
}

.p-shop-list__inner {
  .l-container {
    padding: 0 width(45, 390);
    @include responsive(md) {
      padding: 0 rem(150);
      max-width: 1714px;
      margin: 0 auto;
    }
  }
}

.p-shop-list {
  @include responsive(md) {
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
  }
}

.p-shop-list__item {
  @include responsive(md) {
    width: width(450, 1414);
  }
  &:not(:nth-child(3n)) {
    @include responsive(md) {
      margin: 0 width(32, 1414) width(50, 1414) 0;
    }
  }
  &:nth-child(3n) {
    @include responsive(md) {
      margin: 0 0 width(50, 1414) !important;
    }
  }
  &:not(:last-child) {
    margin: 0 0 remhalf(70);
    @include responsive(md) {
      margin: 0 width(32, 1414) width(50, 1414) 0;
    }
  }
}

.p-shop-list__link {
  display: block;
  transition: opacity 0.5s $transition-base;
  @include hover {
    opacity: 0.7;
  }
}

.p-shop-list__figure {
  @include img(600, 270);
  @include responsive(md) {
    @include img(450, 252);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-shop-list__itemInfo {
  background-color: #231112;
  padding: remhalf(35) remhalf(37);
  height: remhalf(260);
  @include responsive(md) {
    height: rem(188);
    min-height: 140px;
    padding: rem(23) rem(26);
  }
  address {
    @include fonthalf(22, 40, 400, 100, rem);
    color: $color-white;
    font-style: normal;
    @include responsive(md) {
      @include mfont(11, 14, 24, 400, 100, rem);
    }
  }
}

.p-shop-list__itemInfoTitle {
  @include fonthalf(30, 27, 400, 100, rem);
  color: $color-white;
  padding: 0 0 remhalf(31);
  position: relative;
  margin: 0 0 remhalf(12);
  @include responsive(md) {
    @include mfont(12, 18, 27, 400, 100, rem);
    padding: 0 0 rem(18);
    margin: 0 0 rem(18);
  }
  &:before {
    content: '';
    width: remhalf(72);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 0;
    @include responsive(md) {
      width: rem(60);
    }
  }
}
