/* p-home-collection
----------------------------------------- */
@use '../../global' as *;

.p-home-collection {
  background-color: #231112;
  position: relative;
  padding: remhalf(72) 0 remhalf(100);
  @include responsive(md) {
    padding: rem(90) 0 rem(165);
  }
  &:before {
    content: '';
    width: remhalf(280);
    height: remhalf(280);
    background-color: #231112;
    border-radius: 50%;
    position: absolute;
    top: remhalf(-68);
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(280);
      height: rem(280);
      top: rem(-65);
    }
  }
  .l-section-title {
    position: relative;
  }
  .l-container {
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1440px;
    }
  }
}

.p-home-collection__inner {
  margin: remhalf(98) width(90, 780) 0;
  @include responsive(md) {
    @include flex(space-between, flex-start, row);
    margin: rem(64) 0 0;
  }
}

.p-home-collection__block {
  @include responsive(md) {
    width: width(627, 1280);
  }
  &:not(:first-child) {
    .p-home-collection__imgWrap {
      display: none;
      @include responsive(md) {
        @include flex(space-between, flex-start, row);
      }
    }
  }
  &:nth-child(2) {
    margin: remhalf(39) 0 0;
    @include responsive(md) {
      margin: 0;
    }
    .p-home-collection__buttonText {
      &:before {
        left: remhalf(-70);
        width: remhalf(60);
        height: remhalf(60);
        background-image: url(../images/home/collection_icon_02.svg);
        @include responsive(md) {
          width: rem(40);
          height: rem(40);
          left: rem(-50);
        }
      }
    }
    .p-home-collection__figure {
      &.--one {
        width: width(627, 627);
        @include img(627, 408);
        margin: 0 0 width(22, 627);
      }
      &.--two {
        width: width(303, 627);
        @include img(303, 193);
      }
      &.--three {
        width: width(303, 627);
        @include img(303, 193);
      }
    }
  }
  &:first-child {
    .p-home-collection__figure {
      &.--one {
        width: width(290, 600);
        @include img(290, 345);
        @include responsive(md) {
          width: width(305, 627);
          @include img(305, 366);
        }
      }
      &.--two {
        width: width(290, 600);
        @include img(290, 189);
        @include responsive(md) {
          width: width(305, 627);
          @include img(305, 199);
        }
      }
      &.--three {
        width: width(290, 600);
        @include img(290, 222);
        margin: width(21, 600) 0 0;
        @include responsive(md) {
          margin: width(22, 627) 0 0;
          width: width(305, 627);
          @include img(305, 234);
        }
      }
      &.--four {
        width: width(290, 600);
        @include img(290, 382);
        margin: width(-140, 600) 0 0;
        @include responsive(md) {
          margin: width(-147, 627) 0 0;
          width: width(305, 627);
          @include img(305, 402);
        }
      }
    }
  }
}

.p-home-collection__imgWrap {
  @include flex(space-between, flex-start, row);
  flex-wrap: wrap;
  margin: 0 0 remhalf(50);
  @include responsive(md) {
    margin: 0 0 rem(52);
  }
  &.is-anim {
    .p-home-collection__figure {
      filter: blur(0);
      opacity: 1;
      &.--one {
        transition-delay: 0.5s;
      }
      &.--two {
        transition-delay: 0.8s;
      }
      &.--three {
        transition-delay: 1.1s;
      }
      &.--four {
        transition-delay: 1.4s;
      }
    }
  }
}

.p-home-collection__figure {
  transition: filter 1.1s $transition-base, opacity 1.1s $transition-base, -webkit-filter 1.1s $transition-base;
  opacity: 0;
  filter: blur(1.5rem);
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-home-collection__button {
  position: relative;
  background: #8f7f58;
  padding: remhalf(30) 0 remhalf(30) remhalf(46);
  @include flex(center, center, row);
  transition: opacity 1.1s $transition-base;
  @include responsive(md) {
    padding: rem(19) 0 rem(23) rem(44);
    width: width(460, 627);
    margin: 0 auto;
  }
  @include hover {
    opacity: 0.7;
  }
}

.p-home-collection__buttonText {
  @include fonthalf(24, 29, 400, 200, rem);
  font-family: $font-family-base02;
  display: inline-block;
  color: $color-white;
  position: relative;
  @include responsive(md) {
    @include mfont(12, 16, 26, 400, 200, rem);
  }
  &:before {
    content: '';
    width: remhalf(40);
    height: remhalf(45);
    background-image: url(../images/home/collection_icon_01.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: remhalf(-58);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(26);
      height: rem(29);
      left: rem(-40);
    }
  }
}

.p-home-collection__buttonArrow {
  display: block;
  background-image: url(../images/home/collection_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  width: remhalf(44);
  height: remhalf(11.7);
  top: 50%;
  right: remhalf(22);
  transform: translate(0%, -50%);
  @include responsive(md) {
    width: rem(34);
    height: rem(9);
    right: rem(21);
    top: calc(50% - 0.3rem);
  }
}
