/* p-ordermade
----------------------------------------- */
@use '../../global' as *;

.p-orderemade-slide {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);

  @include responsive(md) {
    .p-orderemade-slide {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

#js-ordermade-slider-list,
#js-ordermade-example-slider {
  .p-home-voice__sliderItem {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.p-orderemade-schedule-image-wrapper {
  max-width: 800px;
}
