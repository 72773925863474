/* p-multilingual
----------------------------------------- */
@use '../../global' as *;

body {
  &.multilingual {
    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-multilingual {
  &.l-content {
    position: relative;
  }
  .p-feature__content {
    padding: remhalf(92) width(40, 780) remhalf(231);
    @include responsive(md) {
      padding: rem(100) rem(80) rem(150);
    }
    .p-feature__text {
      margin: 0 0 remhalf(150);
      @include fonthalf(24, 44, 400, 200, rem);
      color: $color-white;
      text-align: center;
      @include responsive(md) {
        margin: 0 auto rem(100);
        @include mfont(12, 20, 45, 400, 200, rem);
        width: width(1050, 1760);
      }
    }

    .p-feature__logo {
      margin: 0 auto;
    }
  }
  .p-home-collection {
    padding: remhalf(72) 0 remhalf(200);
    @include responsive(md) {
      padding: rem(90) 0 rem(200);
    }
    .l-container {
      @include responsive(md) {
        max-width: 1920px;
        padding: 0 min(19.8125rem, 74px);
      }
    }
    .p-engagement__inner {
      margin: remhalf(50) auto remhalf(65);
      @include responsive(md) {
        width: rem(1286);
        min-width: 620px;
        margin: rem(50) auto width(30, 1280);
      }
    }
    .p-collection__list {
      .p-collection__item {
        transition: opacity 1.1s ease, transform 1.1s ease, -webkit-transform 1.1s ease;
        transform: translateY(15%);
        opacity: 0;
      }
    }
    .p-collection__list.inview-list.is-trigger {
      .p-collection__item {
        transform: translateY(0%);
        opacity: 1;
        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.3s;
        }
        &:nth-child(4) {
          transition-delay: 0.4s;
        }
        &:nth-child(5) {
          transition-delay: 0.5s;
        }
        &:nth-child(6) {
          transition-delay: 0.6s;
        }
        &:nth-child(7) {
          transition-delay: 0.7s;
        }
        &:nth-child(8) {
          transition-delay: 0.8s;
        }
        &:nth-child(9) {
          transition-delay: 0.9s;
        }
        &:nth-child(10) {
          transition-delay: 1s;
        }
        &:nth-child(11) {
          transition-delay: 1.1s;
        }
        &:nth-child(12) {
          transition-delay: 1.2s;
        }
        &:nth-child(13) {
          transition-delay: 1.3s;
        }
        &:nth-child(14) {
          transition-delay: 1.4s;
        }
        &:nth-child(15) {
          transition-delay: 1.5s;
        }
        &:nth-child(16) {
          transition-delay: 1.6s;
        }
      }
    }
    .p-collection__desc {
      text-align: center;
      @include responsive(md) {
        margin: 0 0 rem(15);
      }
    }
    .c-button__inner {
      margin: 0 width(40, 780);
      @include responsive(md) {
        width: rem(1286);
        margin: 0 auto;
        @include flex(center, flex-start, row);
      }
      .c-button {
        @include responsive(md) {
          width: width(413, 1286);
          min-width: 200px;
        }
        &:not(:last-child) {
          margin: 0 0 remhalf(40);
          @include responsive(md) {
            margin: 0 width(24, 1286) 0 0;
          }
        }
      }
    }
  }
}

.p-feature__lead {
  &.--multilingual {
    .p-feature__text {
      @include fonthalf(24, 44, 400, 200, rem);
      color: $color-white;
      text-align: center;
      @include responsive(md) {
        width: width(1050, 1760);
        margin: 0 auto;
        @include mfont(12, 20, 45, 400, 200, rem);
        width: width(1050, 1920);
      }
    }
  }
}

.p-multilingual-lead {
  padding: remhalf(16) width(40, 780) remhalf(117);
  background-color: #0f0304;
  @include responsive(md) {
    padding: rem(83) 0 rem(121);
  }
  .p-feature__text {
    @include fonthalf(24, 44, 400, 200, rem);
    color: $color-white;
    text-align: center;
    @include responsive(md) {
      width: width(1050, 1760);
      margin: 0 auto;
      @include mfont(12, 20, 45, 400, 200, rem);
      width: width(1050, 1920);
    }
  }
}

.p-multilingual-leadTitle {
  @include fonthalf(30, 45, 400, 200, rem);
  color: $color-white;
  text-align: center;
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    @include mfont(18, 30, 45, 400, 200, rem);
    margin: 0 0 rem(30);
  }
}

.p-multilingual-leadTitleSub {
  @include fonthalf(26, 45, 400, 200, rem);
  color: $color-white;
  text-align: center;
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    @include mfont(14, 26, 45, 400, 200, rem);
    margin: 0 0 rem(46);
  }
}

// p-multilingual-material
.p-multilingual-material {
  background: linear-gradient(180deg, rgb(15, 3, 4) 0%, rgb(55, 15, 18) 100%);
  padding: rem(50) 0 remhalf(190);
  @include responsive(md) {
    padding: rem(100) 0 rem(223);
  }
  .l-container {
    padding: 0 width(89, 780);
    @include responsive(md) {
      max-width: 1920px;
      padding: 0 min(27.5rem, 74px);
      margin: 0 auto;
    }
  }
}

.p-multilingual-material__inner {
  @include responsive(md) {
    width: rem(1040);
    min-width: 620px;
    margin: 0 auto;
  }
}

.p-multilingual-material__list {
}

.p-multilingual-material__item {
  @include responsive(md) {
    @include flex(flex-start, flex-start, row);
  }
  &:not(:last-child) {
    margin: 0 0 remhalf(139);
    @include responsive(md) {
      margin: 0 0 rem(134);
    }
  }
  &:nth-child(2n) {
    @include responsive(md) {
      @include flex(flex-start, flex-start, row-reverse);
    }
    .p-multilingual-material__figure {
      @include responsive(md) {
        margin: 0 0 0 width(36, 1040);
      }
    }
  }
}

.p-multilingual-material__figure {
  @include img(601, 354);
  margin: 0 0 remhalf(50);
  @include responsive(md) {
    width: width(470, 1040);
    margin: 0 width(36, 1040) 0 0;
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-multilingual-material__itemContent {
  @include responsive(md) {
    width: width(534, 1040);
  }
}

.p-multilingual-material__itemTitle {
  padding: 0 0 remhalf(10);
  margin: 0 0 remhalf(14);
  @include fonthalf(30, 45, 400, 200, rem);
  color: $color-white;
  position: relative;
  @include responsive(md) {
    @include mfont(16, 26, 45, 400, 200, rem);
    margin: 0 0 rem(12);
    padding: 0 0 rem(12);
  }
  &:before {
    content: '';
    width: remhalf(60);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 0;
    @include responsive(md) {
      width: rem(60);
    }
  }
}

.p-multilingual-material__itemText {
  @include fonthalf(22, 40, 400, 100, rem);
  font-family: $font-family-base02;
  color: $color-white;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}

// p-multilingual-faq
.p-multilingual-faq {
  background-color: #202020;
  .p-home-faq__wrap {
    padding: remhalf(100) 0 remhalf(200);
    @include responsive(md) {
      margin: 0 auto;
      padding: rem(100) 0 rem(200);
    }
    .l-section-title__eng {
      padding: 0;
      &:before {
        display: none;
      }
    }
    .p-home-faq__listInner {
      margin: remhalf(53) 0 0;
      @include responsive(md) {
        width: rem(1050);
        max-width: 1050px;
        min-width: 620px;
        margin: rem(82) auto 0;
        padding: 0;
      }
    }
  }
}

// p-multilingual-shop
.p-multilingual-shop {
  padding: remhalf(100) 0 remhalf(200);
  background-color: #0f0304;
  @include responsive(md) {
    padding: rem(100) 0 rem(70);
  }
  .l-container {
    @include responsive(md) {
      max-width: 1920px;
      padding: 0 min(15.8125rem, 43px);
      margin: 0 auto;
    }
  }
  .l-section-title__eng {
    padding: 0;
    &:before {
      display: none;
    }
  }
  .p-shop-list {
    margin: remhalf(50) 0 0;
    padding: 0 remhalf(50);
    @include responsive(md) {
      margin: rem(52) auto 0;
      padding: 0;
      width: rem(1414);
      min-width: 620px;
    }
    &.inview-list.is-trigger {
      .p-shop-list__item {
        transform: translateY(0%);
        opacity: 1;
        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.3s;
        }
        &:nth-child(4) {
          transition-delay: 0.4s;
        }
        &:nth-child(5) {
          transition-delay: 0.5s;
        }
        &:nth-child(6) {
          transition-delay: 0.6s;
        }
        &:nth-child(7) {
          transition-delay: 0.7s;
        }
        &:nth-child(8) {
          transition-delay: 0.8s;
        }
        &:nth-child(9) {
          transition-delay: 0.9s;
        }
        &:nth-child(10) {
          transition-delay: 1s;
        }
        &:nth-child(11) {
          transition-delay: 1.1s;
        }
        &:nth-child(12) {
          transition-delay: 1.2s;
        }
      }
    }
  }
  .p-shop-list__item {
    transform: translateY(15%);
    opacity: 0;
    transition: opacity 1.1s ease, transform 1.1s ease, -webkit-transform 1.1s ease;
  }
  .p-shop-list__item:not(:last-child) {
    @include responsive(md) {
      margin: 0 width(32, 1414) width(30, 1414) 0 !important;
    }
  }
  .p-shop-list__item:not(:nth-child(3n)) {
    @include responsive(md) {
      margin: 0 width(32, 1414) width(30, 1414) 0 !important;
    }
  }
  .p-shop-list__item:nth-child(3n) {
    @include responsive(md) {
      margin: 0 0 width(30, 1414) !important;
    }
  }
  .p-shop-list__itemInfo {
    height: remhalf(165);
    @include responsive(md) {
      height: rem(122);
      min-height: 80px;
    }
  }
}

// p-multilingual-contact
.p-multilingual-contact {
  background-color: #0f0304;
  padding: remhalf(100) 0 remhalf(200);
  @include responsive(md) {
    padding: rem(100) 0 rem(200);
  }
  .l-container {
    @include responsive(md) {
      max-width: 960px;
      padding: 0 rem(80);
      margin: 0 auto;
    }
  }
  .l-section-title__eng {
    padding: 0;
    &:before {
      display: none;
    }
  }
  .p-request-form__progress {
    margin: remhalf(37) 0 0;
    @include responsive(md) {
      margin: rem(52) 0 0;
      padding: 0 rem(54);
    }
  }
  .l-form__buttonInner {
    margin: 0 width(40, 780);
    @include responsive(md) {
      margin: 0;
    }
  }
}
