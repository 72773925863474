/* menu
----------------------------------------- */
@use '../global' as *;

.l-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: -1;
  display: block;
  visibility: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  padding: remhalf(193) rem(20) rem(25.5);
  background-color: #0f0304;
  opacity: 0;
  transition: all 0.5s ease;

  &.is-active {
    z-index: 2;
    visibility: visible;
    opacity: 1;
    animation-duration: 0.5s;
  }
  .l-footer__infoBottom {
    position: static;
    transform: translate(0%, 0%);
  }
  .l-footer__nav {
    margin: remhalf(93) 0 remhalf(80);
  }
  .p-banner {
    margin: 0 0 remhalf(80);
    display: block !important;
    .l-container {
      padding: 0;
    }
  }
  .l-footer__copy {
    margin: 0;
  }
  .l-footer__item {
    border-bottom: 1px solid #202020;
    &:first-child {
      border-top: 1px solid #202020;
    }
  }
}

.l-menu__buttonWrap {
  padding: 0 remhalf(50);
  .c-button {
    margin: remhalf(-28) 0 0;
  }
}

.l-menu__buttonList {
  @include flex(flex-start, flex-start, row);
  flex-wrap: wrap;
}

.l-menu__buttonItem {
  width: width(172, 600);
  &:not(:nth-child(3n)) {
    margin: 0 width(41, 600) width(80, 600) 0;
  }
  &:nth-child(3n) {
    margin: 0 0 width(80, 600) 0;
  }
}

.l-menu__buttonLink {
  display: block;
  img {
    width: 100%;
  }
}

.l-menu__buttonText {
  margin: remhalf(15) 0 0;
  display: block;
  text-align: center;
  color: $color-white;
  @include fonthalf(24, 36, 400, 100, rem);
  font-family: $font-family-base02;
  white-space: nowrap;
}

.c-button {
}

.c-button__text {
}

.c-button__arrow {
}
