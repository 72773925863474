/* link
----------------------------------------- */
@use '../global' as *;

.c-link__inner {
  padding: 0 width(40, 780) 0 0;
  @include flex(flex-end, flex-start, row);
  @include responsive(md) {
    padding: 0;
  }
}

.c-link {
  display: block;
}

.c-link__text {
  color: $color-white;
  @include fonthalf(24, 26, 400, 100, rem);
  font-family: $font-family-english;
  display: block;
  margin: 0 0 remhalf(5);
  @include responsive(md) {
    @include mfont(12, 18, 20, 400, 100, rem);
    margin: 0 0 rem(6);
  }
}

.c-link__lineWrap {
  display: block;
}

.c-link__line {
  display: block;
  background-color: $color-white;
  height: 1px;
  &:first-of-type {
    width: remhalf(58);
    margin: 0 0 remhalf(8);
    @include responsive(md) {
      width: rem(44);
      margin: 0 0 rem(4);
    }
  }
  &:nth-of-type(2) {
    width: remhalf(45);
    margin: 0 0 remhalf(8);
    @include responsive(md) {
      width: rem(34);
      margin: 0 0 rem(4);
    }
  }
  &:nth-of-type(3) {
    width: remhalf(32);
    @include responsive(md) {
      width: rem(24);
    }
  }
}
