@use '../global' as *;

// ===========================
// text
// ===========================

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-garamond {
  font-family: 'adobe-garamond-pro', serif !important;
}
