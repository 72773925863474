/* c-breadcrumbs
----------------------------------------- */
@use '../global' as *;

.c-breadcrumbs {
  padding: remhalf(17) width(40, 780);
  @include responsive(md) {
    padding: rem(37) rem(70);
  }
  &.--multilingual {
    background-color: #0f0304;
    @include responsive(md) {
      position: absolute;
      top: rem(80);
      left: 0;
      background-color: rgba(255, 255, 255, 0);
    }
    .c-breadcrumbs__item {
      &:before {
        @include responsive(md) {
          color: #0f0304;
        }
      }
    }
    .c-breadcrumbs__link {
      @include responsive(md) {
        color: #0f0304;
      }
    }
  }
}

.c-breadcrumbs__list {
  display: unset;
}

.c-breadcrumbs__item {
  display: unset;
  position: relative;
  &:not(:last-child) {
    padding: 0 remhalf(10) 0 0;
    @include responsive(md) {
      padding: 0 12px 0 0;
    }
    &:before {
      content: '>';
      color: $color-white;
      @include fonthalf(18, 27, 400, 100, rem);
      position: absolute;
      top: calc(50% + 15%);
      right: remhalf(3);
      transform: translate(0%, -50%);
      font-family: $font-family-base02;
      @include responsive(md) {
        @include mfont(9, 14, 21, 400, 100, rem);
        top: rem(13);
        right: 4px;
      }
    }
  }
}

.c-breadcrumbs__link {
  color: $color-white;
  @include fonthalf(18, 27, 400, 100, rem);
  display: unset;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(11, 14, 21, 400, 100, rem);
  }
}
