/* page-hero
----------------------------------------- */
@use '../global' as *;

.p-hero {
  margin: remhalf(190) 0 0;
  position: relative;
  @include responsive(md) {
    margin: rem(100) 0 0;
  }
}

.p-hero-has-subtext {
  padding: remhalf(190) 0 0;
  @include responsive(md) {
    padding: rem(100) 0 0;
  }
}

.p-hero-subtext {
  @include fonthalf(24, 48, 400, 200, rem);
  padding: remhalf(20) width(40, 780) 0;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
    padding: rem(100) rem(80) 0;
  }
}

.p-hero__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
}

.p-hero__titleEng {
  font-family: $font-family-english;
  @include fonthalf(50, 54, 400, 100, rem);
  background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 52.08%, #f9f8f5 52.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  text-align: center;
  margin: 0 0 remhalf(22);
  position: relative;
  @include responsive(md) {
    @include mfont(32, 50, 54, 400, 100, rem);
    margin: 0 0 rem(22);
  }
  &:before {
    content: '';
    width: remhalf(60);
    height: 1px;
    background-color: $color-gold;
    position: absolute;
    bottom: remhalf(-22);
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(60);
      bottom: rem(-22);
    }
  }
}

.p-hero__titleJp {
  margin: remhalf(34) 0 0;
  display: block;
  color: #fff;
  @include fonthalf(22, 31.6, 400, 200, rem);
  text-align: center;
  @include responsive(md) {
    margin: rem(34) 0 0;
    @include mfont(15, 22, 32, 400, 200, rem);
  }
}

.p-hero__figure {
  @include img(780, 250);
  @include responsive(md) {
    @include img(1920, 300);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #0f0304;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include responsive(md) {
      display: none;
    }
  }
}
