/* p-engagement
----------------------------------------- */
@use '../../global' as *;

body {
  &.engagement,
  &.marriage {
    .p-banner {
      background-color: #0f0304;
      padding: remhalf(100) 0 0;
      @include responsive(md) {
        padding: rem(100) 0;
      }
    }
    .p-home-type__list {
      .p-home-type__item {
        transform: translateY(0%);
        opacity: 1;
      }
    }
  }
}

.p-engagement {
  background-color: #231112;
  .p-home-type {
    @include responsive(md) {
      padding: rem(80) 0 rem(60);
    }
    .p-home-type__list {
      margin: remhalf(50) 0 0;
      @include responsive(md) {
        margin: rem(50) 0 0;
      }
      .p-home-type__item {
        &:last-child {
          .p-home-type__itemNAme {
            color: $color-white;
          }
        }
      }
    }
  }
}

.p-engagement-terms {
  padding: remhalf(30) 0 remhalf(90);
  @include responsive(md) {
    padding: rem(5) 0 rem(100);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1136px;
    }
  }
}

.p-engagement-modal__wrap {
  background-color: #202020;
  border: remhalf(5) solid #8f7f58;
  position: relative;
  padding: remhalf(50);
  @include responsive(md) {
    padding: rem(30) rem(175) rem(49);
    border: rem(3) solid #8f7f58;
  }
  &:before {
    content: '';
    width: calc(100% - 0.625rem);
    height: calc(100% - 0.625rem);
    border: remhalf(1) solid #8f7f58;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0);
    @include responsive(md) {
      width: calc(100% - 0.75rem);
      height: calc(100% - 0.75rem);
    }
  }
}

.p-engagement-modal__openInner {
  position: relative;
  z-index: 1;
}

.p-engagement-modal__openWrap {
}

.p-engagement-modal__openTitle {
  color: $color-white;
  @include fonthalf(24, 32, 400, 200, rem);
  text-align: center;
  font-family: $font-family-base02;
  margin: 0 0 remhalf(36);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
    margin: 0 0 rem(20);
  }
}

.p-engagement-modal__open {
  color: $color-white;
  @include fonthalf(22, 32, 400, 100, rem);
  text-align: center;
  padding: remhalf(24) 0;
  border: remhalf(4) solid $color-gold;
  width: 100%;
  background-color: #8f7f58;
  transition: background-color 0.5s $transition-base, color 0.5s $transition-base;
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
    padding: rem(7.5) 0;
    border: rem(2) solid $color-gold;
  }
  @include hover {
    color: #8f7f58;
    background-color: $color-white;
  }
}

.p-engagement-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s $transition-base, visibility 0.3s $transition-base;
  &[aria-hidden='false'] {
    opacity: 1;
    visibility: visible;
  }
  &[aria-hidden='true'] {
    opacity: 0;
    visibility: hidden;
  }
}

.p-engagement-modal__overlay {
  background-color: #0f0304;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-engagement-modal__contentInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 width(30, 698);
  @include responsive(md) {
    min-width: 450px;
    margin: 0 width(118, 1284);
  }
}

.p-engagement-modal__content {
  height: 100%;
  padding: 0 0 remhalf(280);
  border-top: remhalf(3) solid $color-white;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @include responsive(md) {
    padding: 0 0 rem(272);
  }
}

.p-engagement-modal__container {
  border: remhalf(1) solid $color-white;
  background-color: #202020;
  width: width(698, 780);
  height: calc(100% - 15rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  @include responsive(md) {
    min-width: 600px;
    width: width(1286, 1920);
  }
}

.p-engagement-modal__header {
}

.p-engagement-modal__title {
  text-align: center;
  color: $color-white;
  @include fonthalf(24, 32, 400, 200, rem);
  font-family: $font-family-base02;
  padding: remhalf(70) 0;
  @include responsive(md) {
    padding: rem(70) 0;
    @include mfont(16, 24, 32, 400, 200, rem);
  }
}

.p-engagement-modal__closeIcon {
  display: block;
  width: remhalf(50);
  height: remhalf(50);
  position: absolute;
  top: remhalf(-2);
  right: remhalf(-2);
  background-color: $color-white;
  z-index: 2;
  @include responsive(md) {
    width: rem(50);
    height: rem(50);
    top: rem(-2);
    right: rem(-2);
  }
  &:before {
    content: '';
    width: remhalf(30);
    height: remhalf(30);
    background-image: url(../images/engagement/modal_close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include responsive(md) {
      width: rem(30);
      height: rem(30);
    }
  }
}

.p-engagement-modal__block {
  padding: remhalf(55) 0 0;
  @include responsive(md) {
    min-width: 450px;
    width: width(814, 1048);
    padding: rem(40) 0 0;
    margin: 0 auto;
  }
  &:first-of-type {
    padding: remhalf(48) 0 0;
    @include responsive(md) {
      padding: rem(30) 0 0;
    }
  }
  .p-engagement-sec__text {
    @include flex(flex-start, center, row);
    @include responsive(md) {
      margin: rem(-8) 0 rem(14);
      position: relative;
      top: inherit;
      left: inherit;
    }
  }
}

.p-engagement-modal__blockTitle {
  @include fonthalf(22, 32, 400, 200, rem);
  color: $color-white;
  font-family: $font-family-base02;
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
    margin: 0 0 rem(13);
  }
}

.p-engagement-modal__blockList {
  @include flex(flex-start, flex-start, row);
  flex-wrap: wrap;
}

.p-engagement-modal__blockItem {
  width: width(304, 638);
  @include responsive(md) {
    width: width(196, 814);
  }
  input {
    display: none;
    &:checked + label {
      background-color: $color-gold;
      color: $color-white;
    }
  }
  label {
    cursor: pointer;
    display: block;
    @include fonthalf(20, 32, 700, 100, rem);
    font-family: $font-family-base02;
    color: $color-gold;
    background-color: #f9f8f5;
    border-radius: rem(2.5);
    padding: remhalf(14) 0;
    text-align: center;
    @include responsive(md) {
      min-height: 32px;
      height: rem(60);
      @include mfont(10, 14, 20, 700, 100, rem);
      border-radius: rem(5);
      padding: 0;
      @include flex(center, center, row);
    }
  }
  &:not(:nth-child(2n)) {
    margin: 0 width(30, 638) width(30, 638) 0;
    @include responsive(md) {
      margin: 0 width(10, 814) width(10, 814) 0;
    }
  }
  &:not(:nth-child(4n)) {
    @include responsive(md) {
      margin: 0 width(10, 814) width(10, 814) 0;
    }
  }
  &:nth-child(2n) {
    margin: 0 0 width(30, 638) 0;
    @include responsive(md) {
      margin: 0 width(10, 814) width(10, 814) 0;
    }
  }
  &:nth-child(4n) {
    @include responsive(md) {
      margin: 0 0 width(10, 814) 0;
    }
  }
}

.p-engagement-modal__search {
  margin: rem(15) 0 0;
  @include flex(center, center, column-reverse);
  @include responsive(md) {
    width: width(814, 1048);
    margin: rem(90) auto 0;
    @include flex(space-between, center, row);
  }
}

.p-engagement-modal__resetButton {
  border-radius: 0;
  border: remhalf(3) solid $color-white;
  color: $color-white;
  background-color: rgba(255, 255, 255, 0);
  color: $color-white;
  padding: remhalf(30) 0;
  @include fonthalf(24, 26, 400, 200, rem);
  text-align: center;
  width: width(207, 317);
  margin: 0 auto;
  font-family: $font-family-base02;
  transition: opacity 0.5s $transition-base;
  cursor: pointer;
  position: relative;
  @include responsive(md) {
    min-width: 160px;
    width: rem(290);
    padding: rem(20) 0;
    @include mfont(12, 16, 26, 400, 200, rem);
    border: rem(1) solid $color-white;
    margin: 0;
  }
  @include hover {
    opacity: 0.7;
  }
}

.p-engagement-modal__submitButtonWrap {
  position: relative;
  width: width(207, 317);
  margin: 0 auto rem(15);
  @include responsive(md) {
    min-width: 160px;
    width: rem(290);
    margin: 0;
  }
  &:before {
    z-index: 1;
    content: '';
    display: block;
    background-image: url(../images/common/button_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: remhalf(35);
    height: remhalf(21);
    position: absolute;
    top: 50%;
    right: remhalf(25);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(33);
      height: rem(20);
      right: rem(20);
    }
  }
}

.p-engagement-modal__submitButton {
  border-radius: 0;
  width: 100%;
  border: remhalf(3) solid $color-gold;
  color: $color-white;
  background-color: #8f7f58;
  color: $color-white;
  padding: remhalf(30) 0;
  @include fonthalf(24, 26, 400, 200, rem);
  text-align: center;
  font-family: $font-family-base02;
  transition: opacity 0.5s $transition-base;
  cursor: pointer;
  position: relative;
  @include hover {
    opacity: 0.7;
  }
  @include responsive(md) {
    padding: rem(20) 0;
    @include mfont(12, 16, 26, 400, 200, rem);
    border: rem(1) solid $color-gold;
  }
}

.p-engagement-sec {
  padding: remhalf(90) 0 remhalf(100);
  @include responsive(md) {
    position: relative;
    padding: rem(80) 0 rem(97);
  }
  &.--results {
    background-color: #0f0304;
    padding: remhalf(53) 0 remhalf(77);
    @include responsive(md) {
      padding: rem(52) 0 0;
    }
    .p-engagement-sec__text {
      margin: 0 0 remhalf(50);
      @include responsive(md) {
        position: static;
        margin: 0 0 rem(38);
      }
    }
    .p-collection__desc {
      text-align: left;
    }
  }
  &.--collection {
    background-color: #0f0304;
    .p-collection__item {
      .p-collection__figure {
        @include img(280, 280);
        @include responsive(md) {
          height: rem(298);
        }
      }
      .p-collection__desc {
        text-align: center;
        @include responsive(md) {
          margin: 0 0 rem(15);
        }
      }
    }
  }
  &.--ordermade {
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1446px;
    }
  }
  .l-section-title {
    margin: 0 0 remhalf(60);
    @include responsive(md) {
      margin: 0 0 rem(62);
    }
  }
  .p-collection__list {
    margin: 0 0 remhalf(25);
    @include responsive(md) {
      margin: 0;
    }
  }
}

.p-engagement-sec__text {
  margin: 0 0 remhalf(40);
  @include flex(center, center, row);
  @include responsive(md) {
    margin: 0;
    position: absolute;
    top: rem(88);
    left: rem(190);
  }
  .p-engagement-sec__text--info {
    display: block;
    margin: 0 remhalf(15) 0 0;
    background-image: url(../images/engagement/info_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: remhalf(60);
    height: remhalf(60);
    @include responsive(md) {
      margin: 0 rem(7) 0 0;
      width: rem(40);
      height: rem(40);
    }
  }
  .p-engagement-sec__text--text {
    display: block;
    @include fonthalf(20, 30, 400, 100, rem);
    color: $color-white;
    font-family: $font-family-base02;
    @include responsive(md) {
      @include mfont(8, 14, 26, 400, 100, rem);
    }
  }
}

.p-engagement__inner {
  width: width(600, 700);
  margin: 0 auto;
  @include responsive(md) {
    width: 100%;
  }
}

.p-engagement-sec__results {
  @include flex(center, center, row);
  margin: 0 0 remhalf(37);
  @include responsive(md) {
    margin: 0 0 rem(29);
  }
}

.p-engagement-sec__resultsText {
  @include fonthalf(34, 76, 400, 200, rem);
  color: #fff;
  display: block;
  border-bottom: remhalf(3) solid $color-white;
  @include responsive(md) {
    @include mfont(20, 34, 76, 400, 200, rem);
    border-bottom: rem(3) solid $color-white;
  }
}

.p-engagement-sec__resultsNumber {
  @include fonthalf(34, 76, 400, 200, rem);
  color: #fff;
  display: block;
  border-bottom: remhalf(3) solid $color-white;
  @include responsive(md) {
    @include mfont(20, 34, 76, 400, 200, rem);
    border-bottom: rem(3) solid $color-white;
  }
}
