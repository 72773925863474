/* language-switch
----------------------------------------- */
@use '../global' as *;

.l-language-switch__list {
  @include flex(flex-start, center, row);
  @include md {
    position: absolute;
    top: 50%;
    right: width(300, 1920);
    transform: translate(0%, -50%);
  }
  @include responsive(lg) {
    position: absolute;
    top: 50%;
    right: width(244, 1920);
    transform: translate(0%, -50%);
  }
}

.l-language-switch__item {
  &:not(:last-child) {
    padding: 0 rem(18) 0 0;
    position: relative;
    @include responsive(md) {
      padding: 0 rem(28) 0 0;
    }
    &:before {
      content: '/';
      @include fonthalf(20, 20, 400, 100, rem);
      font-family: $font-family-base02;
      position: absolute;
      top: 50%;
      right: rem(5);
      transform: translate(0%, -50%);
      color: $color-white;
      @include responsive(md) {
        right: rem(9);
        @include mfont(12, 16, 24, 400, 100, rem);
      }
    }
  }
}

.l-language-switch__link {
  transition: color 0.5s $transition-base;
  display: block;
  color: $color-white;
  font-family: $font-family-base02;
  @include fonthalf(20, 20, 400, 100, rem);
  @include responsive(md) {
    @include mfont(12, 16, 24, 400, 100, rem);
  }
  @include hover {
    color: #8f7f58;
  }
}
