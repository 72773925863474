/* p-privacy
----------------------------------------- */
@use '../../global' as *;

body {
  &.privacy {
    * {
      word-break: auto-phrase;
    }

    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-privacy {
  background-color: #0f0304;
  color: $color-white;
  padding: remhalf(30) 0 remhalf(90);
  @include responsive(md) {
    padding: rem(5) 0 rem(100);
  }
}

.p-privacy-container {
  .p-home-column {
    padding-top: remhalf(80);
  }
}

.p-privacy-intro {
  text-align: center;
  font-family: $font-family-base02;
  position: relative;
  @include fonthalf(22, 40, 400, 100, rem);

  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}

.p-privacy-items {
  display: flex;
  flex-direction: column;
  gap: rem(48);

  * {
    font-family: $font-family-base02;
    @include fonthalf(22, 40, 400, 100, rem);

    @include responsive(md) {
      @include mfont(12, 16, 32, 400, 200, rem);
    }
  }

  h3 {
    margin-bottom: rem(16);
    @include fonthalf(28, 40, 700, 100, rem);
    @include responsive(md) {
      @include mfont(14, 16, 32, 700, 200, rem);
    }
  }
}
