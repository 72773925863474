/* after
----------------------------------------- */
@use '../../global' as *;

.p-after-row-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(40);
  flex-wrap: wrap;

  .p-after-row-links__item {
    width: 320px;
    text-align: center;
  }
}
