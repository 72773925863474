/* hamburger
----------------------------------------- */
@use '../global' as *;

.l-hamburger__toggle {
  z-index: 3;
  display: block;
  width: rem(46);
  height: rem(39);
  padding: rem(10);
  cursor: pointer;
  transition: all 0.5s ease;
  @include responsive(md) {
    display: none;
  }

  &.is-active {
    .l-hamburger__toggleWrap {
      span {
        &:nth-of-type(1) {
          transform: translateY(0.6rem) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-0.55rem) rotate(45deg);
        }
      }
    }
  }
  &:hover {
    opacity: 1;
  }
}

.l-hamburger__toggleWrap {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  span {
    position: absolute;
    display: inline-block;
    width: rem(26);
    height: rem(1);
    background-color: #f9f8f5;
    transition: all 0.5s ease;
    &:nth-of-type(1) {
      top: 0;
      left: 0;
    }
    &:nth-of-type(2) {
      top: rem(9);
      left: 0;
      transition: all 0.5s ease;
    }
    &:nth-of-type(3) {
      bottom: 0;
      left: 0;
    }
  }
}
