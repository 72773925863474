/* p-home-shop
----------------------------------------- */
@use '../../global' as *;

.p-home-shop {
  background-color: #000;
  padding: remhalf(100) 0 remhalf(124);
  @include responsive(md) {
    padding: rem(100) 0;
  }
  .l-container {
    padding: 0 width(90, 780);
    @include responsive(md) {
      max-width: 1210px;
      padding: 0 rem(80);
    }
  }
  .l-section-title__eng {
    &:before {
      background-color: $color-white;
    }
  }
}

.p-home-shop__listInner {
  margin: remhalf(100) 0 0;
  @include responsive(md) {
    margin: rem(77) 0 0;
    @include flex(space-between, flex-start, row);
  }
}

.p-home-shop__list {
  @include responsive(md) {
    width: width(500, 1050);
  }
}

.p-home-shop__item {
  border-bottom: 1px solid #202020;
  &:first-child {
    border-top: 1px solid #202020;
  }
}

.p-home-shop__itemTitle {
  position: relative;
  padding: remhalf(24) 0 remhalf(29);
  @include fonthalf(24, 27, 400, 200, rem);
  color: $color-white;
  cursor: pointer;
  @include responsive(md) {
    @include mfont(12, 16, 27, 400, 200, rem);
    padding: rem(16) 0;
  }
  &.--open {
    .p-home-shop__itemTitle--toggle {
      &:after {
        transform: translate(-50%, -50%) rotateZ(90deg);
      }
    }
  }
}

.p-home-shop__itemTitle--text {
}

.p-home-shop__itemTitle--toggle {
  position: absolute;
  top: 50%;
  right: remhalf(28);
  transform: translate(0%, -50%);
  width: remhalf(24);
  height: remhalf(24);
  display: block;
  @include responsive(md) {
    right: rem(24);
    width: 14px;
    height: 14px;
  }
  &:before {
    content: '';
    width: remhalf(24);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include responsive(md) {
      width: 14px;
    }
  }
  &:after {
    transition: transform 0.5s $transition-base;
    content: '';
    width: 1px;
    height: remhalf(24);
    background-color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include responsive(md) {
      height: 14px;
    }
  }
}

.p-home-shop__itemContent {
  display: none;
}

.p-home-shop__itemFigure {
  @include img(600, 271);
  @include responsive(md) {
    @include img(500, 226);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-home-shop__itemInfo {
  background-color: #231112;
  padding: remhalf(35) remhalf(37) remhalf(38);
  @include responsive(md) {
    padding: rem(22) rem(31);
  }
  address {
    @include fonthalf(22, 40, 400, 100, rem);
    font-style: normal;
    color: $color-white;
    @include responsive(md) {
      @include mfont(12, 14, 24, 400, 100, rem);
    }
  }
}

.p-home-shop__itemInfoTitle {
  @include fonthalf(30, 27, 400, 100, rem);
  padding: 0 0 remhalf(30);
  margin: 0 0 remhalf(12);
  color: $color-white;
  position: relative;
  @include responsive(md) {
    @include mfont(12, 18, 30, 400, 100, rem);
    padding: 0 0 rem(18);
    margin: 0 0 rem(18);
  }
  &:before {
    content: '';
    width: remhalf(72);
    height: 1px;
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 0;
    @include responsive(md) {
      width: rem(60);
    }
  }
}
