/* p-feature
----------------------------------------- */
@use '../../global' as *;

body {
  &.feature {
    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-feature {
}

.p-feature__video {
  height: 100%;
  width: 100%;
  video {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.p-feature {
}

.p-feature-hero {
  padding: remhalf(190) 0 0;
  background-color: #0f0304;
  @include responsive(md) {
    position: relative;
    padding: rem(100) 0 0;
  }
}

.p-feature-hero__inner {
  position: relative;
}

.p-feature-hero__figure {
  @include img(780, 1284);
  opacity: 0.6;
  @include responsive(md) {
    @include img(1920, 980);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-feature-hero__content {
  width: widthvw(617, 780);
  height: widthvw(601, 780);
  position: absolute;
  right: widthvw(22, 780);
  bottom: widthvw(52, 780);
  z-index: 1;
  background-image: url(../images/feature/hero_ornament_sp.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: widthvw(147, 780) widthvw(56, 780) widthvw(52, 780);
  @include responsive(md) {
    width: rem(826);
    height: rem(568);
    right: rem(172);
    bottom: rem(32);
    background-image: url(../images/feature/hero_ornament_pc.svg);
    padding: rem(196) rem(48) rem(83);
  }
}

.p-feature-hero__title {
  color: #8f7f58;
  text-align: center;
  font-size: widthvw(30, 780);
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 2.5333333333;
  padding: 0 0 rem(1.5);
  position: relative;
  margin: 0 0 rem(7.5);
  @include responsive(md) {
    @include mfont(18, 30, 76, 400, 200, rem);
    padding: 0 0 rem(2);
    margin: 0 0 rem(11);
  }
  &:before {
    content: '';
    width: widthvw(21, 390);
    height: 1px;
    background-color: #8f7f58;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      width: rem(42);
    }
  }
}

.p-feature-hero__text {
  text-align: center;
  color: $color-white;
  font-size: widthvw(24, 780);
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1.8333333333;
  @include responsive(md) {
    @include font(18, 40, 400, 200, rem);
  }
}

.p-feature-hero__nav {
  padding: remhalf(47) width(36, 780) 0;
  @include responsive(md) {
    position: absolute;
    padding: 0;
    bottom: 0;
    left: rem(75);
  }
}

.p-feature-hero__navTitle {
  width: remhalf(170);
  height: rem(17);
  margin: 0 0 remhalf(12);
  @include responsive(md) {
    width: rem(170);
    height: rem(26);
    margin: 0 0 rem(27);
  }
}

.p-feature-hero__list {
  padding: 0 0 remhalf(30) remhalf(25);
  position: relative;
  @include flex(flex-start, flex-start, row);
  flex-wrap: wrap;
  @include responsive(md) {
    display: block;
    padding: 0 0 rem(64) rem(25);
  }
  &:before {
    content: '';
    width: 1px;
    height: remhalf(115);
    background-color: $color-white;
    position: absolute;
    bottom: 0;
    left: 0;
    @include responsive(md) {
      height: rem(234);
    }
  }
}

.p-feature-hero__item {
  width: width(120.5, 341.5);
  @include responsive(md) {
    width: inherit;
  }
  &:not(:last-child) {
    @include responsive(md) {
      margin: 0 0 rem(32);
    }
  }
  &:not(:nth-child(2n)) {
    margin: 0 rem(5) 0 0;
    @include responsive(md) {
      margin: 0 0 rem(32);
    }
  }
}

.p-feature-hero__link {
  padding: 0 0 0 remhalf(31);
  position: relative;
  @include fonthalf(22, 50, 400, 200, rem);
  color: $color-white;
  transition: color 0.2s $transition-base;
  @include responsive(md) {
    padding: 0 0 0 rem(31);
    @include mfont(8, 16, 16, 400, 200, rem);
  }
  &:before {
    content: '';
    width: remhalf(20);
    height: remhalf(20);
    background-image: url(../images/feature/nav_arrow_white.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: calc(50% + 0.15rem);
    left: 0;
    transform: translate(0%, -50%);
    @include responsive(md) {
      top: calc(50% + 0.05rem);
      width: rem(20);
      height: rem(20);
    }
  }
  @include hover {
    color: $color-gold;
    &:before {
      background-image: url(../images/feature/nav_arrow_gold.svg);
    }
  }
}

.p-feature__inner {
}

.p-feature__video-sec {
  height: 100vh;
  position: relative;
}

.p-feature__lead {
  background-color: #0f0304;
  padding: remhalf(64) width(40, 780) remhalf(264);
  position: relative;
  @include responsive(md) {
    padding: rem(80) 0 rem(280);
  }
  &:before {
    content: '';
    width: 1px;
    height: remhalf(200);
    background-color: $color-gold;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    @include responsive(md) {
      height: rem(200);
    }
  }
}

.p-feature__text {
  color: $color-white;
  text-align: center;
  @include fonthalf(24, 44, 400, 200, rem);
  @include responsive(md) {
    @include mfont(12, 20, 45, 400, 200, rem);
  }
}

.p-feature__content {
  background-color: #0f0304;
  padding: remhalf(64) width(40, 780) remhalf(189);
  @include responsive(md) {
    padding: rem(80) rem(80) rem(80);
  }
  .p-feature__text {
    margin: 0 0 remhalf(112);
    @include responsive(md) {
      margin: 0 0 rem(140);
    }
  }
}

.p-feature__logo {
  display: block;
  width: widthvw(404, 780);
  height: widthvw(99, 780);
  margin: 0 auto remhalf(172);
  background-image: url(../images/feature/logo.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @include responsive(md) {
    width: rem(404);
    height: rem(99);
    margin: 0 auto rem(186);
  }
}

.p-feature__listInner {
  background-image: url(../images/feature/bg_sp.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: widthvw(104, 780) widthvw(80, 780) widthvw(163, 780);
  @include responsive(md) {
    margin: 0 auto;
    max-width: 1520px;
    background-image: url(../images/feature/bg_pc.png);
    padding: rem(128) rem(154) rem(132);
  }
}

.p-feature__listTitle {
  width: widthvw(468, 780);
  margin: widthvw(12, 780) auto 0;
  @include responsive(md) {
    width: rem(468);
    margin: 0 auto 0;
    // margin: rem(130) auto 0;
  }
}

.p-feature__list {
  margin: widthvw(66, 780) 0 0;
  @include responsive(md) {
    margin: rem(88) 0 0;
    @include flex(center, flex-start, row);
    flex-wrap: wrap;
  }
}

.p-feature__item {
  padding: 0 0 widthvw(82, 780);
  @include responsive(md) {
    width: rem(546);
    padding: 0;
  }
  &:not(:last-child) {
    margin: 0 0 widthvw(100, 780);
    border-bottom: 1px solid rgba(249, 248, 245, 0.2);
    @include responsive(md) {
      margin: 0 0 rem(123);
      border-bottom: none;
    }
  }
  &:not(:nth-child(2n)) {
    @include responsive(md) {
      margin: 0 rem(120) rem(123) 0;
    }
  }
  &:nth-child(2n) {
    @include responsive(md) {
      margin: 0 0 rem(123);
    }
  }
  &:last-child {
    padding: 0;
  }
}

.p-feature__figure {
  @include img(546, 353);
  margin: 0 0 widthvw(40, 780);
  @include responsive(md) {
    margin: 0 0 rem(34);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-feature__itemContent {
}

.p-feature__itemTitle {
  @include flex(center, center, row);
  margin: 0 0 widthvw(29, 780);
  @include responsive(md) {
    margin: 0 0 rem(23);
  }
}

.p-feature__itemTitle--number {
  margin: 0 widthvw(9, 780) widthvw(-5, 780) 0;
  display: block;
  font-size: 4.7435vw;
  line-height: 1;
  letter-spacing: 0.1em;
  font-weight: 400;
  font-style: italic;
  background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 52.08%, #f9f8f5 52.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: $font-family-english;
  @include responsive(md) {
    @include font(37, 37, 400, 100, rem);
    margin: 0 rem(9) rem(-5) 0;
  }
}

.p-feature__itemTitle--text {
  display: block;
  font-size: 3.5897vw;
  line-height: 1;
  letter-spacing: 0.2em;
  font-weight: 400;
  background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 52.08%, #f9f8f5 52.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include responsive(md) {
    @include font(28, 28, 400, 200, rem);
  }
}

.p-feature__itemText {
  font-family: $font-family-base02;
  color: $color-white;
  font-size: widthvw(22, 780);
  line-height: 1.81818;
  letter-spacing: 0.2em;
  font-weight: 400;
  margin: 0 0 widthvw(47, 780);
  @include responsive(md) {
    height: rem(96);
    @include font(16, 32, 400, 200, rem);
    margin: 0 0 rem(40);
  }
}

.p-feature__itemLink {
  white-space: nowrap;
  width: widthvw(158, 780);
  margin: 0 0 0 auto;
  display: block;
  font-size: widthvw(16, 780);
  font-weight: 400;
  letter-spacing: 0.2em;
  font-family: $font-family-base02;
  color: $color-white;
  padding: 0 widthvw(44.5, 780) widthvw(14, 780) 0;
  border-bottom: 1px solid $color-white;
  position: relative;
  @include responsive(md) {
    width: rem(137);
    @include font(13, 26, 400, 200, rem);
    padding: 0 rem(43) rem(11) 0;
  }
  &:before {
    content: '';
    background-image: url(../images/feature/link_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: widthvw(32, 780);
    height: widthvw(19, 780);
    position: absolute;
    top: widthvw(-2, 780);
    right: 0;
    @include responsive(md) {
      width: rem(32);
      height: rem(19);
      top: rem(2.5);
    }
  }
}
