/* diamond
----------------------------------------- */
@use '../../global' as *;

.p-diamond {
  .p-hero-subtext {
    word-break: auto-phrase;

    .p-diamond__subtext-copy {
      @include fonthalf(40, 48, 400, 200, rem);
      @include responsive(md) {
        @include mfont(16, 28, 32, 400, 200, rem);
      }
    }

    &.p-diamond__subtext-sub {
      padding-top: rem(24);
    }
  }
}

.p-diamond-container {
  :not(h3) {
    font-family: $font-family-base02;
    word-break: auto-phrase;
  }

  @include fonthalf(24, 48, 400, 200, rem);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}
