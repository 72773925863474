/* p-home-news
----------------------------------------- */
@use '../../global' as *;

.p-home-news {
  padding: remhalf(150) 0;
  position: relative;
  @include md {
    padding: rem(150) rem(140) 0;
    background-color: #231112;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
  }
  @include responsive(lg) {
    padding: rem(150) rem(244) 0;
    background-color: #231112;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      left: rem(10);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      right: rem(10);
    }
  }
}

.p-home-news__inner {
  @include responsive(md) {
    @include flex(space-between, flex-start, row);
  }
  .l-section-title {
    @include md {
      flex-shrink: 0;
      margin: 0 rem(56) 0 0;
    }
    @include responsive(lg) {
      width: width(176, 1052);
    }
  }
}

.p-home-news__content {
  margin: remhalf(102) width(40, 780) 0;
  @include responsive(md) {
    margin: 0;
    width: width(814, 1052);
  }
}

.p-home-news__list {
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    margin: 0 0 rem(40);
  }
}

.p-home-news__item {
  border-bottom: 1px solid rgba(191, 183, 163, 0.2);
  &:first-child {
    border-top: 1px solid rgba(191, 183, 163, 0.2);
  }
}

.p-home-news__link {
  padding: remhalf(35) 0 remhalf(38);
  display: block;
  transition: opacity 0.5s $transition-base;
  @include responsive(md) {
    padding: rem(36) 0;
    @include flex(flex-start, center, row);
  }
  time {
    display: block;
    @include fonthalf(20, 30, 400, 100, rem);
    font-family: $font-family-base02;
    color: $color-white;
    @include responsive(md) {
      @include mfont(12, 12, 18, 400, 100, rem);
      margin: 0 rem(33) 0 0;
      flex-shrink: 0;
    }
  }
  @include hover {
    opacity: 0.7;
  }
}

.p-home-news__itemTitle {
  @include fonthalf(24, 48, 400, 200, rem);
  font-family: $font-family-base02;
  color: $color-white;
  @include line-clamp(1);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}
