/* p-propose
----------------------------------------- */
@use '../../global' as *;

.p-propose-container {
  :not(h3) {
    font-family: $font-family-base02;
    word-break: auto-phrase;
  }

  .p-propose-recommend-box {
    max-width: 360px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @include responsive(md) {
      margin-left: 0;
    }
  }

  .p-propose-overflow-table {
    overflow-x: scroll;
    margin-right: calc(-50vw + 50%);
    padding-right: width(40, 780);

    @include responsive(md) {
      overflow: auto;
      margin-right: 0;
      padding-right: 0;
    }
  }
}
