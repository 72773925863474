/* p-ring-head
----------------------------------------- */
@use '../../global' as *;

.p-ring-head {
  margin: remhalf(190) 0 0;
  @include responsive(md) {
    overflow: hidden;
    position: relative;
    margin: rem(100) 0 0;
  }
  .l-container {
    @include responsive(md) {
      max-width: 860px;
      position: absolute;
      top: calc(50% + 2%);
      left: calc(50% + 18%);
      transform: translate(-50%, -50%);
    }
  }
}

.p-ring-head__figure {
  @include img(780, 700);
  @include responsive(md) {
    @include img(1920, 700);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-ring-head__content {
  margin: remhalf(-34) 0 0;
  background-color: #0f0304;
  @include responsive(md) {
    margin: 0;
    background-color: rgba(255, 255, 255, 0);
  }
}

.p-ring-head__text {
  padding: remhalf(50) 0 remhalf(84);
  @include fonthalf(24, 44, 400, 200, rem);
  text-align: center;
  color: $color-white;
  @include responsive(md) {
    padding: rem(30) 0 0;
    @include font(18, 40, 400, 200, rem);
  }
}
