/* _p-common (移動コーディングなどで使用)
----------------------------------------- */
@use '../../global' as *;

body {
  &.common {
    background: #000;

    * {
      word-break: auto-phrase;
    }

    .p-banner {
      background-color: #0f0304;
    }
  }
}

.p-common {
  background-color: $color-tanzo-dark-brown;
  color: $color-white;
  padding: remhalf(30) 0 remhalf(90);
  @include responsive(md) {
    padding: rem(5) 0 rem(100);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1540px;
    }
  }

  h2 {
    border-bottom: 1px solid $color-border;
    padding-bottom: rem(32);
  }

  h4 {
    margin-bottom: rem(16);
    font-family: $font-family-base;
    @include fonthalf(22, 40, 700, 100, rem);

    @include responsive(md) {
      @include mfont(16, 16, 32, 700, 200, rem);
    }
  }
}

.p-common-container {
  @include fonthalf(24, 48, 400, 200, rem);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
  }
}

.p-common-has-borders-head {
  @include fonthalf(40, 48, 400, 200, rem);
  @include responsive(md) {
    @include font(36, 48, 400, 200, rem);
  }

  padding-top: rem(112);
  text-align: center;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $color-gold;
  }

  &::before {
    margin-bottom: rem(26);
  }

  &::after {
    margin-top: rem(26);
  }
}

.p-common-link-button {
  display: inline-block;
  border: 1px solid $color-gold;
  padding: rem(20) rem(80);
  background-color: $color-gold;
  color: $color-white;

  @include hover {
    background-color: $color-white;
    color: $color-gold;
  }
}

.p-common-table {
  width: 100%;
  border-collapse: collapse;

  .p-common-table__head {
    background-color: $color-tanzo-dark-brown-2;
  }

  th,
  td {
    border: 1px solid $color-border;
    padding: rem(16);
  }
}

.p-common-text-gold {
  color: $color-gold;
}

.p-common-text-bold {
  font-weight: bold;
}

.p-common-caption-box {
  position: relative;
  background-color: $color-tanzo-dark-brown-2;
  border: 3px solid $color-gold;
  display: flex;
  align-items: center;
  justify-content: center;

  .p-common-caption-body {
    padding: remhalf(100) width(80, 768) remhalf(150);
    @include responsive(md) {
      padding: rem(80) width(190, 1920) rem(130);
    }

    &::before {
      left: remhalf(9);
      content: '';
      position: absolute;
      display: block;
      border: 1px solid rgba($color-gold, 0.8);
      top: 4px;
      bottom: 4px;
      left: 4px;
      right: 4px;
    }

    &::after {
      right: remhalf(9);
      background-color: #3d2d23;
    }
  }

  .p-common-caption-head {
    text-align: center;
    margin-bottom: 1rem;
    font-family: $font-family-base;

    &::after {
      content: '';
      display: block;
      width: rem(100);
      height: 1px;
      background-color: $color-gold;
      margin: rem(16) auto;
    }
  }
}

.p-common-gradient {
  background: rgb(55, 15, 18);
  background: -moz-linear-gradient(0deg, rgba(55, 15, 18, 1) 4%, rgba(15, 3, 4, 1) 25%);
  background: -webkit-linear-gradient(0deg, rgba(55, 15, 18, 1) 4%, rgba(15, 3, 4, 1) 25%);
  background: linear-gradient(0deg, rgba(55, 15, 18, 1) 4%, rgba(15, 3, 4, 1) 25%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#370f12",endColorstr="#0f0304",GradientType=1);
}

.p-common-bg-brown {
  background-color: $color-tanzo-dark-brown;
}

.p-common-bg-brown-2 {
  background-color: $color-tanzo-dark-brown-2;
}

.p-common-tablist-container {
  padding-top: rem(50);
  padding-bottom: rem(50);

  @include responsive(md) {
    padding-top: rem(90);
  }
}

.p-common-tablist-title {
  padding-bottom: rem(17);
}

.p-common-tablist {
  color: $color-white;
  display: flex;
  flex-wrap: wrap;
  gap: rem(10);

  [role='tab'] {
    color: $color-white;
    border: 1px solid $color-white;
    padding: 0.5rem 1rem;
    transition: 0.5s;
    @include fonthalf(20, 32, 400, 200, rem);
    @include responsive(md) {
      @include mfont(12, 14, 24, 400, 200, rem);
    }

    @include hover {
      border-color: $color-gold;
      background-color: $color-gold;
    }
  }
}

.p-common-pager {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(60);

  .p-common-pager__main {
    display: flex;
    align-items: center;
    @include responsive(md) {
      gap: rem(32);
    }
  }

  .p-common-pager__item {
    a,
    span {
      font-family: $font-family-base;
      width: rem(32);
      height: rem(32);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @include responsive(md) {
        width: rem(40);
        height: rem(40);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        border: 1px solid $color-tanzo-dark-brown-2;
        border-radius: 99999px;
        width: rem(26);
        height: rem(26);
        top: 50%;
        left: 50%;
        transform: translate(-53%, -50%);
        transition: 0.5s;

        @include responsive(md) {
          width: rem(50);
          height: rem(50);
        }

        @include responsive(lg) {
          width: rem(40);
          height: rem(40);
        }
      }

      &[aria-current='page']::after {
        border-color: $color-white;
      }
    } // a,span

    @include hover {
      a:not(.p-common-pager__control) {
        &::after {
          border-color: $color-white;
        }
      }
    }
  }

  .prev {
    padding-right: rem(8);
  }

  .next {
    padding-left: rem(8);
  }
}

/**** デコレーション ****/
.p-common-deco-container {
  padding: 0 0 remhalf(150);
  width: 100%;
  margin: 0 auto;

  @include responsive(md) {
    padding: 0 width(190, 1920) rem(130);
  }
}

.p-common-deco-inner {
  padding: remhalf(150) width(40, 780);
  position: relative;
  @include md {
    padding: rem(150) rem(140) 0;
    background-color: #231112;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  @include responsive(lg) {
    padding: rem(150) rem(244) 0;
    background-color: #231112;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      left: rem(10);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      right: rem(10);
    }
  }
}

.p-common-deco__top {
  @include responsive(md) {
    background-image: url(../images/home/brown_bg_top_pc.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: rem(126);
    position: absolute;
    top: rem(-126);
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.p-common-deco__top-sp {
  background-image: url(../images/home/brown_bg_top_sp.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: remhalf(200);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
  @include responsive(md) {
    display: none;
  }
}

.p-common-deco__bottom {
  background-size: cover;

  @include responsive(md) {
    background-image: url(../images/home/brown_bg_bottom_pc.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    width: 100%;
    height: rem(140);
    position: absolute;
    bottom: rem(-140);
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.p-common-deco__bottom-sp {
  background-image: url(../images/home/brown_bg_bottom_sp.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: remhalf(150);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  @include responsive(md) {
    display: none;
  }
}

.p-common-deco-body {
  margin: remhalf(38) 0 0;
  padding: remhalf(10) width(40, 780) 0;
  position: relative;
  background-color: $color-tanzo-dark-brown-2;
  @include md {
    padding: rem(10) rem(140) 0;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  @include responsive(lg) {
    padding: rem(10) rem(244) 0;
    background-color: $color-tanzo-dark-brown-2;
    max-width: 1540px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      left: rem(10);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: remhalf(9.5);
    height: 100%;
    background-color: #8f7f58;
    width: rem(0.5);
    @include responsive(md) {
      width: 1px;
      right: rem(10);
    }
  }

  .p-common-deco-body__inner {
    padding: remhalf(200) 0 0;
    @include responsive(md) {
      padding: rem(100) 0 0;
    }
  }

  .p-common-deco-body__article {
    margin-top: 5rem;
  }
}
/**** /デコレーション ****/
