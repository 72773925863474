/* p-home-passion
----------------------------------------- */
@use '../../global' as *;

.p-home-passion {
  background-color: #000;
  padding: remhalf(162) width(90, 780) remhalf(92);
  @include responsive(md) {
    padding: rem(150) 0 rem(238);
  }
  .l-container {
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1446px;
    }
  }
}

.p-home-passion__title {
  padding: remhalf(58) remhalf(124) remhalf(48);
  background-image: url(../images/home/passion_title_img.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @include flex(center, center, row);
  @include responsive(md) {
    padding: rem(62) 0 rem(44);
  }
}

.p-home-passion__title--large {
  background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 67.71%, #f9f8f5 67.72%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include fonthalf(40, 40, 400, 120, rem);
  display: block;
  @include responsive(md) {
    @include mfont(20, 38, 38, 400, 120, rem);
  }
}

.p-home-passion__title--small {
  background: linear-gradient(180deg, #f9f8f5 0%, #9b9b9b 67.71%, #f9f8f5 67.72%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @include fonthalf(30, 40, 400, 120, rem);
  display: block;
  margin: 0 remhalf(3) 0 0;
  @include responsive(md) {
    @include mfont(13, 25, 38, 400, 120, rem);
  }
}

.p-home-passion__inner {
  margin: remhalf(46) 0 0;
  @include responsive(md) {
    margin: rem(52) 0 0;
  }
}

.p-home-passion__block {
  &:first-child {
    @include responsive(md) {
      @include flex(space-between, flex-start, row-reverse);
    }
    .p-home-passion__figure {
      margin: 0 0 remhalf(125);
      @include responsive(md) {
        width: width(429, 1286);
        margin: 0 rem(68) 0 0;
      }
    }
    .p-home-passion__text {
      @include responsive(md) {
        margin: rem(20) 0 0;
      }
    }
  }
  &:nth-child(2) {
    padding: 0 0 remhalf(264);
    position: relative;
    @include responsive(md) {
      padding: 0;
      margin: rem(53) 0 0;
      @include flex(space-between, flex-start, row);
    }
    .p-home-passion__figure {
      position: absolute !important;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      @include img(780, 405);
      width: 100vw;
      @include responsive(md) {
        margin: rem(16) 0 0;
        width: width(639, 1286);
        position: static !important;
        transform: translate(0%, 0%);
      }
      &:before {
        @include responsive(md) {
          padding-top: 0;
        }
      }
    }
    .p-home-passion__text {
      margin: 2em 0 0;
      @include responsive(md) {
        margin: 0;
      }
    }
  }
}

.p-home-passion__figure {
  position: relative;
  transform: translateY(0%);
  filter: blur(1.5rem);
  transition: opacity 1.1s $transition-base, transform 1.1s $transition-base, filter 1.1s $transition-base;
  &.is-anim {
    filter: blur(0);
  }
}

.p-home-passion__figureText {
  background-image: url(../images/home/passion_text.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: remhalf(470);
  height: remhalf(202);
  display: block;
  position: absolute;
  bottom: remhalf(-132);
  right: remhalf(-76);
  @include responsive(md) {
    display: none;
  }
}

.p-home-passion__text {
  color: $color-white;
  @include fonthalf(24, 48, 400, 200, rem);
  @include responsive(md) {
    width: width(606, 1286);
    @include mfont(12, 18, 40, 400, 200, rem);
  }
}
