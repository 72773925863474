@use 'sass:math';
@use '../global' as *;

// ===========================
// base
// ===========================

html {
  box-sizing: border-box;

  @include lg {
    font-size: math.div(100vw, 192) * 1.6;
  }
}

* {
  box-sizing: inherit;

  margin: 0;

  font-family: $font-family-base;

  -webkit-font-smoothing: antialiased;

  &::before,
  ::after {
    box-sizing: inherit;

    margin: 0;
  }
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
input,
textarea,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;

  padding: 0;

  border: 0;

  // vertical-align: baseline;
}

body {
  overflow-x: hidden;

  height: 100%;

  color: $color-text;

  counter-reset: number 0;

  letter-spacing: 0.1em;

  font-weight: 500;

  font-size: rem(16);

  font-family: $font-family-base;

  line-height: 1.15;

  overscroll-behavior: none;

  // font-feature-settings: 'palt' 1;
  font-kerning: normal;

  -webkit-font-smoothing: antialiased;

  -webkit-text-size-adjust: 100%;
}

h1 {
  margin: 0;
}

img {
  width: 100%;

  height: auto;

  border: none;

  vertical-align: top;
}

img,
iframe,
video {
  max-width: 100%;
}

button {
  padding: 0;

  border: none;

  background-color: transparent;

  cursor: pointer;

  -webkit-appearance: none;

  -moz-appearance: none;

  appearance: none;
}

ol li,
ul li {
  list-style: none;
}

a {
  // outline: none;
  color: inherit;

  text-decoration: none;

  transition: 0.5s;
}

a[href^='tel:'] {
  @include responsive(md) {
    pointer-events: none;
  }
}

// キーボード操作"以外"でフォーカスされた際はoutlineを消す
.js-focus-visible:focus:not(.focus-visible) {
  outline: 0;
}

// スマホではフォーカス時のアウトライン削除
*:focus-visible {
  outline: none;

  @include responsive(md) {
    outline-style: auto;

    outline-color: -webkit-focus-ring-color;
  }
}

.wrap {
  @include responsive(md) {
    // overflow: hidden;
  }
}

.svg {
  width: 100%;

  height: 100%;
}
