/* c-pagination
----------------------------------------- */
@use '../global' as *;

.c-pagination {
  margin: remhalf(45) 0 0;
  @include responsive(md) {
    margin: rem(10) 0 0;
  }
}

.c-pagination__inner {
  @include flex(center, center, row);
}

.c-pagination__arrow {
  @include fonthalf(30, 43, 400, 100, rem);
  color: #fff;
  display: block;
  font-family: $font-family-english;
  position: relative;
  @include responsive(md) {
    @include mfont(11, 18, 20, 400, 100, rem);
  }
  &:before {
    content: '';
    width: remhalf(19);
    height: remhalf(36);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(12);
      height: rem(22);
    }
  }
  &.--prev {
    padding: 0 0 0 remhalf(30);
    margin: 0 remhalf(23.5) 0 0;
    @include responsive(md) {
      padding: 0 0 0 rem(22);
      margin: 0 rem(23) 0 0;
    }
    &:before {
      left: 0;
      background-image: url(../images/common/pagination_arrow_prev.svg);
    }
  }
  &.--next {
    padding: 0 remhalf(30) 0 0;
    margin: 0 0 0 remhalf(23.5);
    @include responsive(md) {
      padding: 0 rem(22) 0 0;
      margin: 0 0 0 rem(23);
    }
    &:before {
      right: 0;
      background-image: url(../images/common/pagination_arrow_next.svg);
    }
  }
}

.c-pagination__numbers {
  @include fonthalf(30, 43, 700, 0, rem);
  color: #fff;
  display: block;
  margin: 0 remhalf(20.5);
  @include responsive(md) {
    @include mfont(11, 16, 23, 700, 0, rem);
    margin: 0 rem(21);
  }
  &.--current {
    position: relative;
    &:before {
      z-index: 1;
      content: '';
      width: remhalf(47);
      height: remhalf(47);
      border: remhalf(1) solid #fff;
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      top: calc(50% + 0.045rem);
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      @include responsive(md) {
        width: rem(37);
        height: rem(37);
        min-width: 20px;
        min-height: 20px;
      }
    }
  }
  &.--ellipsis {
    span {
      display: block;
      margin: 0 0 remhalf(16);
      line-height: 1;
      @include responsive(md) {
        margin: 0 0 rem(8);
      }
    }
  }
}
