/* p-eternity
----------------------------------------- */
@use '../../global' as *;

body {
  &.eternity {
    .p-banner {
      background-color: #0f0304;
    }
    .p-ring-summary {
      background-color: #0f0304;
      padding: rem(31.5) 0 rem(50);
      @include responsive(md) {
        padding: rem(13) 0 rem(100);
      }
    }
    .p-ring-summary__block {
      &.p-ring-summary__block--about {
        .p-ring-summary__list {
          @include responsive(md) {
            @include flex(flex-start, flex-start, row);
          }
          .p-ring-summary__item {
            &:first-child {
              .p-ring-summary__desc {
                @include responsive(md) {
                  margin: 0 0 rem(57);
                }
              }
            }
          }
        }
        .p-ring-summary__bannerLink {
          @include responsive(md) {
            margin: -17.3% 0 0 auto;
          }
        }
      }
    }
  }
}

.p-eternity {
}

.p-eternity-feature {
  padding: rem(63) 0 rem(80);
  background: linear-gradient(180deg, rgba(15, 3, 4, 1) 0%, rgba(55, 15, 18, 1) 100%);
  @include responsive(md) {
    padding: rem(148) 0 rem(75);
  }
  .l-container {
    padding: 0 width(20, 390);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1327px;
    }
  }
}

.p-eternity__inner {
  margin: rem(30) 0 0;
  @include responsive(md) {
    margin: rem(100) 0 0;
  }
}

.p-eternity__block {
  &:not(:first-child) {
    margin: remhalf(65.5) 0 0;
    @include responsive(md) {
      margin: rem(80) 0 0;
    }
  }
  &:first-child {
    @include responsive(md) {
      @include flex(space-between, flex-start, row);
    }
    .p-eternity__text {
      @include responsive(md) {
        width: width(772, 1167);
      }
    }
    .p-eternity__figure {
      @include responsive(md) {
        width: width(365, 1167);
      }
    }
  }
  &:nth-child(2) {
    @include responsive(md) {
      @include flex(space-between, flex-start, row-reverse);
    }
    .p-eternity__text {
      @include responsive(md) {
        width: width(674, 1167);
      }
    }
    .p-eternity__figure {
      @include responsive(md) {
        width: width(457, 1167);
      }
    }
  }
}

.p-eternity__text {
  color: $color-white;
  @include fonthalf(22, 40, 400, 100, rem);
  font-family: $font-family-base02;
  margin: 0 0 rem(25);
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 100, rem);
    margin: 0;
  }
}

.p-eternity__figure {
}

.p-eternity-merit {
  background-color: #0f0304;
  padding: rem(52) 0 rem(50);
  @include responsive(md) {
    padding: rem(165) 0;
  }
  .l-container {
    padding: 0 width(20, 390);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1327px;
    }
  }
}

.p-eternity-merit__block {
  &:not(:first-child) {
    margin: rem(59) 0 0;
    @include responsive(md) {
      margin: rem(165) 0 0;
    }
  }
}

.p-eternity-merit__title {
  @include fonthalf(30, 30, 400, 200, rem);
  padding: 0 0 rem(20);
  border-bottom: 1px solid rgba(191, 183, 163, 0.2);
  color: $color-white;
  margin: 0 0 remhalf(33);
  @include responsive(md) {
    padding: 0 0 rem(39);
    margin: 0 0 rem(33);
    @include mfont(18, 30, 25, 400, 200, rem);
  }
}

.p-eternity-merit__list {
}

.p-eternity-merit__item {
  padding: 0 0 0 remhalf(38);
  @include fonthalf(22, 40, 400, 200, rem);
  font-family: $font-family-base02;
  position: relative;
  color: $color-white;
  @include responsive(md) {
    @include mfont(12, 16, 32, 400, 200, rem);
    padding: 0 0 0 rem(38);
  }
  &:not(:last-child) {
    margin: 0 0 1.8em;
    @include responsive(md) {
      margin: 0 0 2em;
    }
  }
  &:before {
    content: '';
    background-image: url(../images/eternity/merit_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: remhalf(24);
    height: remhalf(30);
    position: absolute;
    top: rem(3.5);
    left: 0;
    @include responsive(md) {
      top: calc(50% + 0.1rem);
      transform: translate(0%, -50%);
      width: rem(24);
      min-width: 12px;
      height: rem(30);
      min-height: 15px;
    }
  }
}

.p-eternity-merit__description {
  margin: rem(50) 0 0;
  @include responsive(md) {
    margin: rem(100) 0 0;
  }
}

.p-eternity-merit__descriptionTitle {
  @include fonthalf(26, 32, 400, 200, rem);
  color: $color-white;
  padding: 0 0 remhalf(29);
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 18, 32, 400, 200, rem);
    padding: 0 0 rem(22);
  }
}

.p-eternity-merit__descriptionList {
  @include responsive(md) {
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
  }
}

.p-eternity-merit__descriptionItem {
  padding: rem(12) 0;
  border-bottom: 1px solid rgba(191, 183, 163, 0.2);
  @include flex(flex-start, center, row);
  @include responsive(md) {
    min-width: 300px;
    width: width(304, 1167);
    padding: rem(6) 0;
    margin: 0 width(24, 1167) 0 0;
    border-top: 1px solid rgba(191, 183, 163, 0.2);
  }
  &:first-child {
    border-top: 1px solid rgba(191, 183, 163, 0.2);
  }
  &:nth-child(n + 3) {
    @include responsive(md) {
      margin: rem(-2.8) width(24, 1167) 0 0;
    }
  }
  span {
    display: block;
    margin: 0 rem(3.5) 0 0;
    color: $color-white;
    @include fonthalf(22, 32, 400, 200, rem);
    font-family: $font-family-base02;
    @include responsive(md) {
      @include mfont(12, 14, 32, 400, 200, rem);
      margin: 0 rem(5) 0 0;
    }
  }
}
