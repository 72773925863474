/* p-banner
----------------------------------------- */
@use '../global' as *;

.p-banner {
  position: relative;
  background-color: #000;
  @include responsive(md) {
    padding: 0 0 100px;
  }
  .l-container {
    padding: 0 width(40, 780) remhalf(275);
    @include responsive(md) {
      padding: 0 rem(80);
      max-width: 1340px;
    }
  }
}

.p-banner__inner {
}

.p-banner__list {
  @include responsive(md) {
    @include flex(space-between, flex-start, row);
  }
}

.p-banner__item {
  @include responsive(md) {
    width: width(563, 1180);
  }
  &:not(:last-child) {
    margin: 0 0 remhalf(36);
    @include responsive(md) {
      margin: 0;
    }
  }
}

.p-banner__link {
  display: block;
  transition: opacity 0.5s $transition-base;
  @include hover {
    opacity: 0.7;
  }
}

.p-banner__figure {
}
