/* p-ring
----------------------------------------- */
@use '../../global' as *;

body {
  &.ring {
    .p-banner {
      background-color: #0f0304;
      padding: remhalf(100) 0 0;
      @include responsive(md) {
        padding: rem(100) 0;
      }
    }
  }
}

.p-ring {
  background-color: #231112;
  margin: remhalf(190) 0 0;
  &.--bg-darkbrown {
    background: #0f0304;
  }
  &.--transparency {
    background-color: rgba(255, 255, 255, 0);
    .c-breadcrumbs {
      background-color: #0f0304;
    }
  }
}

.p-ring__video {
  height: 100%;
  width: 100%;
  video {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.p-ring-detail {
  padding: remhalf(41) 0 remhalf(109);
  @include responsive(md) {
    padding: rem(6) 0 rem(64);
  }
  .l-container {
    padding: 0 width(40, 780);
    @include responsive(md) {
      max-width: 1446px;
      padding: 0 rem(80);
      // padding: 0 clamp(54.8px, 5rem, 80px);
    }
  }
}

.p-ring-detail__inner {
  @include responsive(md) {
    width: rem(1286);
    min-width: 658px;
    margin: 0 auto;
    display: grid;
    gap: 0 rem(50);
    grid-template:
      'Slider  Title ' auto
      'Slider  Description ' auto
      'Slider  List ' auto
      'Slider  Annotation ' auto
      'Navigation  Link ' auto;
    // 'Slider  Annotation ' 80px 'Alpha Delta Gamma' auto / 40% 80px 1fr;
  }
}

.p-ring-detail__title {
  padding: remhalf(19) 0 remhalf(28);
  border-top: remhalf(1) solid #8f7f58;
  border-bottom: remhalf(1) solid #8f7f58;
  color: $color-white;
  @include fonthalf(30, 56, 400, 200, rem);
  @include responsive(md) {
    width: rem(638);
    min-width: 326px;
    grid-area: Title;
    padding: rem(18) 0 rem(28);
    @include mfont(18, 26, 50, 400, 200, rem);
    margin: 0 0 rem(31);
  }
}

.p-ring-detail__sliderWrap {
  margin: remhalf(42) 0 remhalf(46);
  @include responsive(md) {
    min-width: 306px;
    width: rem(598);
    grid-area: Slider;
    margin: 0;
  }
}

.splide {
}

.p-ring-detail__sliderMain {
}

.splide__track {
}

.splide__list {
}

.p-ring-detail__sliderMainList {
}

.splide__slide {
}

.p-ring-detail__sliderMainItem {
}

.p-ring-detail__sliderMainFigure {
  @include img(700, 488);
  @include responsive(md) {
    @include img(598, 450);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-ring-detail__sliderThumbnail {
  margin: remhalf(28) 0 0;
  @include responsive(md) {
    margin: rem(26) 0 0;
  }
}

.p-ring-detail__sliderThumbnailList {
  transform: unset !important;
  flex-wrap: wrap;
}

.p-ring-detail__sliderThumbnailItem {
  border: none !important;
  &:not(:nth-child(5n)) {
    margin: 0 1.7142% 1.7142% 0;
    @include responsive(md) {
      margin: 0 1.5886% 1.5886% 0;
    }
  }
  &:nth-child(5n) {
    margin-right: 0 !important;
    margin: 0 0 1.7142%;
    @include responsive(md) {
      margin: 0 0 1.5886%;
    }
  }
}

.p-ring-detail__sliderThumbnailFigure {
  @include img(131, 98);
  @include responsive(md) {
    @include img(112, 84);
  }
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.p-ring-detail__desc {
  color: $color-white;
  @include fonthalf(22, 40, 400, 100, rem);
  font-family: $font-family-base02;
  margin: 0 0 remhalf(58);
  @include responsive(md) {
    width: rem(638);
    min-width: 326px;
    grid-area: Description;
    @include mfont(12, 16, 32, 400, 200, rem);
    margin: 0 0 rem(45);
  }
}

.p-ring-detail__list {
  @include flex(flex-end, flex-start, row);
  flex-wrap: wrap;
  margin: 0 0 remhalf(58);
  @include responsive(md) {
    width: rem(638);
    min-width: 326px;
    grid-area: List;
    margin: 0 0 rem(25);
  }
}

.p-ring-detail__row {
  padding: remhalf(6) 0;
  width: width(333.5, 700);
  border-bottom: remhalf(1) solid #bfb7a3;
  @include flex(flex-start, center, row);
  @include responsive(md) {
    border-top: none;
    border-bottom: remhalf(1) solid #bfb7a3;
    padding: rem(6) 0;
    width: rem(304);
    min-width: 157px;
  }
  &:not(:nth-child(2n)) {
    margin: 0 !important;
  }
  &:nth-child(2n) {
    margin: 0 0 0 width(33, 780);
    @include responsive(md) {
      margin: 0 0 0 rem(30);
    }
  }
  &:first-child,
  &:nth-child(2) {
    border-top: remhalf(1) solid #bfb7a3;
    border-bottom: remhalf(1) solid #bfb7a3;
  }
}

.p-ring-detail__item {
  width: remhalf(74);
  padding: 0 remhalf(25) 0 0;
  margin: 0 remhalf(5) 0 0;
  color: $color-white;
  @include fonthalf(20, 32, 400, 200, rem);
  font-family: $font-family-base02;
  flex-shrink: 0;
  position: relative;
  @include responsive(md) {
    width: rem(54);
    min-width: 38px;
    padding: 0 rem(19) 0 0;
    margin: 0 rem(5) 0 0;
    @include mfont(10, 14, 32, 400, 200, rem);
  }
  &:before {
    content: '：';
    @include fonthalf(20, 32, 400, 200, rem);
    font-family: $font-family-base02;
    color: $color-white;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    @include responsive(md) {
      @include mfont(10, 14, 32, 400, 200, rem);
    }
  }
}

.p-ring-detail__detail {
  color: $color-white;
  @include fonthalf(20, 32, 400, 200, rem);
  font-family: $font-family-base02;
  width: calc(100% - 2.3125rem);
  @include responsive(md) {
    @include mfont(10, 14, 32, 400, 200, rem);
    width: rem(245);
  }
}

.p-ring-detail__annotation {
  color: $color-white;
  @include fonthalf(20, 38, 400, 100, rem);
  font-family: $font-family-base02;
  @include responsive(md) {
    width: rem(638);
    min-width: 326px;
    grid-area: Annotation;
    @include mfont(12, 12, 24, 400, 100, rem);
  }
}

.p-ring-detail__link {
  margin: remhalf(66) 0 remhalf(99);
  @include responsive(md) {
    width: rem(638);
    min-width: 326px;
    grid-area: Link;
    margin: rem(27) 0 0;
  }
  .c-button {
    min-width: unset;
    @include responsive(md) {
      width: 100%;
    }
  }
}

.p-ring-detail__linkWrap {
  @include flex(center, center, row);
  margin: 0 0 remhalf(30);
  @include responsive(md) {
    margin: 0 0 rem(22);
  }
  .c-button {
    width: width(334, 700);
    @include responsive(md) {
      width: rem(308);
      min-width: 157px;
    }
    &:not(:last-child) {
      margin: 0 width(32, 700) 0 0;
      @include responsive(md) {
        margin: 0 rem(22) 0 0;
      }
    }
  }
}

.p-ring-detail__navigationWrap {
  @include responsive(md) {
    @include flex(flex-start, flex-end, row);
    width: rem(598);
    min-width: 306px;
    grid-area: Navigation;
  }
}

.p-ring-detail__navigation {
  @include flex(center, center, row);
}

.p-ring-detail__navigationText {
  @include fonthalf(24, 34, 400, 200, rem);
  color: $color-white;
  position: relative;
  @include responsive(md) {
    white-space: nowrap;
    @include mfont(12, 16, 23, 400, 200, rem);
  }
  &:before {
    content: '';
    width: remhalf(19);
    height: remhalf(36);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: calc(50% + 0.1rem);
    transform: translate(0%, -50%);
    @include responsive(md) {
      width: rem(12);
      height: rem(22);
    }
  }
  &.--prev {
    padding: 0 0 0 remhalf(30);
    margin: 0 remhalf(63) 0 0;
    @include responsive(md) {
      padding: 0 0 0 rem(20);
      margin: 0 rem(93) 0 0;
    }
    &:before {
      left: 0;
      background-image: url(../images/common/pagination_arrow_prev.svg);
    }
  }
  &.--next {
    padding: 0 remhalf(30) 0 0;
    margin: 0 0 0 remhalf(63);
    @include responsive(md) {
      padding: 0 rem(20) 0 0;
      margin: 0 0 0 rem(93);
    }
    &:before {
      right: 0;
      background-image: url(../images/common/pagination_arrow_next.svg);
    }
  }
}

// recommend
.p-ring-detail__recommend {
  padding: remhalf(80) 0 remhalf(82.5);
  background: linear-gradient(180deg, rgba(15, 3, 4, 1) 0%, #370f12 100%);
  @include responsive(md) {
    padding: rem(80) 0 rem(28);
  }
  .l-container {
    @include responsive(md) {
      max-width: 1560px;
      padding: 0 rem(80);
    }
  }
  .p-collection__list {
    flex-wrap: nowrap;
  }
}

.p-ring-detail__recommendSlider {
  margin: remhalf(64) 0 0;
  @include responsive(md) {
    padding: 0 rem(57);
    margin: rem(62) 0 0;
  }
  .splide__arrow {
    display: none;
    @include responsive(md) {
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background: rgba(255, 255, 255, 0);
      opacity: 1;
      width: 15px;
      height: 26px;
      top: calc(50% - 1.7rem);
      background-image: url(../images/home/slider_arrow.svg);
    }
    &.splide__arrow--prev {
      left: 0;
    }
    &.splide__arrow--next {
      transform: translateY(-50%) scaleX(-1);
      right: 0;
    }
    svg {
      display: none;
    }
  }
}
