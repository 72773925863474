/* button
----------------------------------------- */
@use '../global' as *;

.c-button__inner {
}

.c-button {
  width: 100%;
  position: relative;
  background-color: #8f7f58;
  @include flex(center, center, row);
  padding: remhalf(31) 0;
  transition: opacity 0.5s $transition-base;
  @include responsive(md) {
    padding: rem(21) 0;
    width: rem(550);
    min-width: 360px;
    margin: 0 auto;
  }
  @include hover {
    opacity: 0.7;
  }
  &.--icon {
    padding: remhalf(20) 0;
    @include responsive(md) {
      padding: rem(12) 0;
    }
  }
}

.c-button__icon {
  display: block;
  height: remhalf(50);
  width: auto;
  margin: 0 remhalf(16) 0 0;
  @include responsive(md) {
    height: rem(45);
    margin: 0 rem(17) 0 0;
  }
  img {
    height: 100%;
  }
}

.c-button__text {
  color: $color-white;
  @include fonthalf(24, 26, 400, 200, rem);
  font-family: $font-family-base02;
  @include responsive(md) {
    @include mfont(12, 16, 26, 400, 200, rem);
  }
}

.c-button__arrow {
  display: block;
  background-image: url(../images/common/button_arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: remhalf(35);
  height: remhalf(21);
  position: absolute;
  top: 50%;
  right: remhalf(25);
  transform: translate(0%, -50%);
  @include responsive(md) {
    width: rem(33);
    height: rem(20);
    right: rem(20);
  }
}
